import React from 'react'
import { Link } from 'react-router-dom';
export const first = [
    {
        // id: "",
        question: "Uploading Documents",
        answer:
            <div>
                <p>
                    You can upload documents in plain text (TXT), PDF, and Microsoft Office file formats at a maximum file size of 8MB per document.
                </p>
            </div>
    },
    {
        question: 'Digital image scanning',
        answer:
            <div>
                <p className="digitize__big__p">
                    In order to create digital objects that are accurate copies of an original and able to be re-purposed, good practice is to create a digital master,
                    akin to a negative. Lower resolution copies for specific purposes can then be made from the master.
                <p className="digitize__big__p">
                    <br />
                        Scanning black and white images in full color allows tinting, discoloration and any markings on the image to be more clearly visible, while improving
                        the dynamic range of greys available for revealing detail.
                 </p>
                </p>

            </div>
    }]
export const second = [
    {
        question: "Digital Photography",
        answer: <p>
            The output from a digital camera is dependent on its capabilities.
            Generally wherever possible the camera’s highest settings should be used to capture as much detail and colour information as possible. If a camera does not support raw image output, the highest detail setting for JPEG is
            the safest alternative for creating an image that can be re-purposed.
        </p>
    },
    {
        question: "Bit Depth",
        answer: <p>
            48-bit-RGB (16 bit per channel) capture
        </p>
    },
    {
        question: "Capture Format",
        answer: <p>
            CameraRAW or Adobe DNG
        </p>
    },
    {
        question: "Colour Space",
        answer: <p>ProPhoto</p>
    },
    {
        question: `Capture Resolution`,
        answer: <p>
            Minimum of 10 megapixels
        </p>
    }
]