import React from "react";
import { Row, Col, Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import "./header.css";
import Logo from "../../assests/image/logo1.webp";
import { Link } from "react-router-dom";
var { width, height } = window.screen;

const Header = () => {
  return (
    <div
      style={{ borderBottom: "1px solid  #E5E5E5" }}
      className={"navbar__wrapper"}
    >
      <div style={{ width: "93%", margin: "auto" }}>
        <Row style={{ padding: 0, margin: 0 }}>
          <Col md={12} className="nav__header">
            <Navbar
              collapseOnSelect
              bg="#FFFFFF"
              color="#141517"
              className={"nav__bar__font"}
            >
              <Navbar.Brand
                style={{
                  marginLeft: (81 / width) * 100,
                  marginRight: (81 / width) * 100
                }}
              >
                <Link to="/">
                  <img alt="logo" src={Logo} className={"navbar__logo"} />
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse
                id="responsive-navbar-nav"
                style={{
                  marginLeft: (477.37 / width) * 100,
                  marginRight: (73.67 / width) * 100
                }}
              >
                <Nav
                  style={{
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                    marginLeft: "48.2%",
                    height: "9vh"
                  }}
                >
                  <Nav>
                    <Link to="/searchperson" className={"nav__link__font"}>
                      Person Search
                    </Link>
                  </Nav>
                  <Nav>
                    <Link to="/stories" className={"nav__link__font"}>
                      Stories
                    </Link>
                  </Nav>
                  <Nav>
                    <Link to="/participate" className={"nav__link__font"}>
                      How to Partcipate
                    </Link>
                  </Nav>
                  {/* <Nav>
                                    <Link to="/research" className={"nav__link__font"}>Research Guide</Link>
                                </Nav> */}
                  <Nav>
                    <Link to="/faq" className={"nav__link__font"}>
                      FAQ
                    </Link>
                  </Nav>
                  <Nav>
                    <Link to="/about" className={"nav__link__font"}>
                      About
                    </Link>
                  </Nav>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Header;
