import React, { useEffect } from "react";
import Loader from "../../../assests/image/loading.gif";
import NoImage from "../../../assests/image/no-image.webp";
const StoryImg = ({ image, styleClass }) => {
  useEffect(() => {}, [image]);

  return (
    <div>
      <div id="Img">
        {image === null ? (
          <img src={Loader} alt="loading" className="imgary2" />
        ) : image === undefined ? (
          <img src={NoImage} alt="loading" className="imgary2" />
        ) : (
          <img
            src={image}
            alt="story pictures"
            className={styleClass === undefined ? "imgary" : styleClass}
          />
        )}
      </div>
    </div>
  );
};

export default StoryImg;
