import React from "react";
import MHeader from "../header/mobile/mHeader";
import MFooter from "../footer/mFooter";
const MobileLayout = props => {
  return (
    <div>
      <div className="navbar__wrapper">
        <MHeader />
      </div>
      {window.scrollTo(0, 0)}
      {props.children}
      <div>
        <MFooter />
      </div>
    </div>
  );
};

export default MobileLayout;
