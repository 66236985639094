import React, { useState } from "react";
import "./digitalGuideline.css";
import { Link } from "react-router-dom";

import { Row, Container, Col } from "react-bootstrap";
import { first, second } from '../components/questionsAndAnswers';
import Answers from '../components/answers'

const DigitalGuideline = () => {

  var [showHide, setShowHide] = useState({ visibleResponses: {} })

  const toggle = (index) => {
    const exists = showHide.visibleResponses[index];

    if (exists) {
      delete showHide.visibleResponses[index];
    } else {
      showHide.visibleResponses[index] = true;
    }

    setShowHide({ visibleResponses: showHide.visibleResponses });
  }


  return (
    <div>
      <Row
        style={{
          padding: "0",
          margin: "0",
          color: "white",
          background: "black"
        }}
      >
        <div className="body__wrapper">
          <Row style={{ marginTop: "11.3vh" }}>
            <div className={"col-md-4"} style={{ paddingRight: "10vmin" }}>
              <h3 className={"digital__header"}>Digitization Guidelines</h3>
              <p className={"digitize__small__p"}>
                We welcome submission of digital records. These tips will help
                you create online copies of your photos and documents.
              </p>
            </div>

            <div className="col-md-8">
              <div className="row">
                {
                  first.map((x, currentindex) => {
                    let index = `fd${currentindex}`
                    return (
                      <Col
                        md={12}
                        style={{
                          paddingLeft: "10%",
                          marginBottom: "10vh"
                        }}
                        key={currentindex}
                      >
                        <div className="row">
                          <div className="col-md-11">
                            <div className="participate__header"
                              onClick={() => toggle(index)}
                            >
                              <h2
                                className={"participate__header"}
                              >
                                {x.question}
                              </h2>
                            </div>
                          </div>
                          <div className="col-md-1 icon_style" >
                            <i className="fas fa-chevron-down"
                              onClick={() => toggle(index)}
                            ></i>
                          </div>
                        </div>


                        {
                          (showHide.visibleResponses[index]) &&
                          <Answers ans={x.answer} index={index} key={index} />
                        }
                      </Col>
                    )
                  })}

                <Col
                  md={12}
                  style={{
                    paddingLeft: "10%",
                    marginTop: "1vh",
                    marginBottom: "1vh"
                  }}
                >



                  <table>
                    <thead>
                      <tr>
                        <th className={"table__header"}></th>
                        <th className={"table__header"}>Safe</th>
                        <th className={"table__header"}>Best Practice</th>
                      </tr>
                    </thead>
                    <tbody>


                    <tr>
                      <td style={{ color: "#9B9B9B" }}>Bit Depth</td>
                      <td>24-bit RGB (8-bit per channel) capture</td>
                      <td>48-bit RGB (16-bit per channel) capture</td>
                    </tr>
                    <tr>
                      <td style={{ color: "#9B9B9B" }}>File Format</td>
                      <td>
                        Uncompressed TIFF, PDF, JPEG - not more than 30%
                        compression
                      </td>
                      <td>Uncompressed TIFF or JPEG2000</td>
                    </tr>
                    <tr>
                      <td style={{ color: "#9B9B9B" }}>Colour Space</td>
                      <td>sRGB</td>
                      <td>Adobe 1998 (colour)</td>
                    </tr>
                    <tr>
                      <td style={{ color: "#9B9B9B" }}>Capture Resolution</td>
                      <td>300ppi x output length original length</td>
                      <td>400ppi x output length original length</td>
                    </tr>
                    </tbody>
                  </table>
                </Col>


                {
                  second.map((x, currentindex) => {
                    let index = `sd${currentindex}`
                    return (
                      <Col
                        md={12}
                        style={{
                          paddingLeft: "10%",
                          marginBottom: "10vh"
                        }}
                        key={currentindex}

                      >
                        <div className="row">
                          <div className="col-md-11">
                            <div className="participate__header"
                              onClick={() => toggle(index)}
                            >
                              <h2
                                className={"participate__header"}
                              >
                                {x.question}
                              </h2>
                            </div>
                          </div>
                          <div className="col-md-1 icon_style" >
                            <i className="fas fa-chevron-down"
                              onClick={() => toggle(index)}
                            ></i>
                          </div>
                        </div>


                        {
                          (showHide.visibleResponses[index]) &&
                          <Answers ans={x.answer} index={index} key={index} />
                        }
                      </Col>
                    )
                  })}
              </div>
            </div>

          </Row>
        </div>
      </Row>
    </div>
  );
};

export default DigitalGuideline;
