import React from 'react';
import Desktop from './desktop/digitalGuideline';
import Tablet from './tablet/digitalGuideline';
import Mobile from './mobile/mDigitalGuideline';
import { MOBILE, TABLET } from '../../resolution';

export default (props) => {
  if (props.resolution === MOBILE) {
    return <Mobile props={props} />
  } else if (props.resolution === TABLET) {
    return <Tablet props={props} />
  } else {
    return <Desktop props={props} />
  }
}
