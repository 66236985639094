import React from 'react';
import { Row, Col } from 'react-bootstrap';
// import mask from '../../assests/image/Mask.webp';
import participate1 from '../../../assests/image/participate1.webp';
import participateImg from '../../../assests/image/participate.webp';
import participate2 from '../../../assests/image/participate2.webp';
import mask1 from '../../../assests/image/Mask_1.webp';
import bifraTown from '../../../assests/image/biafraTown.webp';
import './dParticipate.css';
import { Stack, CompoundButton } from 'office-ui-fabric-react';
import { Link } from 'react-router-dom';


const Participate = () => {

  return (
    <div>
      <div className="body__wrapper">
        <Row style={{ padding: "0", margin: "0" }}>
          <Col md={12} style={{ padding: "12.0vh 0 0 0" }}>
            <h3 className={"participate__header"} style={{ color: "#000000", marginBottom: "1vh" }}>Add your contribution to a survivor’s life</h3>


            <div className="row">
              <div className="col-md-9">
                <p style={{ fontSize: "24px", paddingTop: "8%", fontFamily: 'poppins', lineHeight: '40px', letterSpacing: '0.01em' }}>
                  We welcome material provided by family members in particular, including digital copies of photos and documents relating to a survivor's
                   life and links to other relevant online resources.
                            </p>
                <p style={{ fontSize: "14px" }}>

                  To add to any record, simply scroll to the record page, simply scroll to the <Link to="/addperson">Add your contribution</Link> section, click the 'contribute' button,
                  and then follow the on-screen instructions. We ask for a little information about you, and also whether you would like your details
                  published for other researchers with an interest in the same survivor to contact you directly. If you don’t wish to contribute information
                                    online, or you cannot find a record for someone who should be recorded, please fill in this <Link to="/addperson">form</Link>

                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Row style={{ padding: "0", margin: "0", background: "black" }}>
        <div className="body__wrapper">
          <Row>
            <Col md={7} style={{ color: "white", height: "79.4vh", display: "flex", justifyContent: "cener", alignItems: "center" }}>
              <div style={{ width: "80%" }}>
                <h5 className={"participate__header"}>Tell a Story</h5>
                <p style={{ fontSize: "15px", lineHeight: "24px" }}>
                  We are very keen to add information about survivors still living in the museum. It is
                  important when contributing information to the record of a living person that you seek
                  permission from this person for this information to be included. The Museum has the right
                  to modify or remove user-contributed material at its sole discretion and without notification.
                                </p>

                <p style={{ fontSize: "15px", lineHeight: "24px" }}>
                  If you are contributing information on a living person please use this <Link to="/addperson">form</Link>
                </p>
              </div>
            </Col>

            <Col md={5} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <div style={{ width: "80%" }}>
                <img src={bifraTown} alt="mask off" width="100%" />
              </div>
            </Col>
          </Row>
        </div>
      </Row>

      <div className="body__wrapper">
        <Row style={{ padding: "5.4vh 0 14.5vh", margin: "0" }}>
          <Col md={6} style={{ display: "flex", alignItems: "center", paddingLeft: "0" }}>
            <div style={{ width: "80%" }}>
              <img src={mask1} alt="mask off 1" width="100%" />
            </div>
          </Col>
          <Col md={6}>
            <div>
              <h5 className={"participate__header"}>Share Pictures/Media of Artefacts</h5>
              <div style={{ fontSize: "15px" }}>
                <p>
                  Letters, emails, diaries and photos are immensely important in telling the stories of the survivors of
                   the Biafra war. If you have this type of material, you can scan and upload it directly onto your service
                   person's record as a JPEG image or a PDF document. Otherwise, please allow us to add it to the memorial museum.
                   We will scan it and send it back to you along with a copy of the finished record for your inspection.
                                    </p>
                <p>
                  If you are sending us this type of content about a person who fought in the war please include the service number of
                  the person your information refers to. This is because the official records often have variations of the same name
                  (e.g. Jack Smith, John Smith, J.A. Smith) and we do our best to keep the biographies connected.
                                    </p>
                <p>
                  Where possible, we would prefer the original photo or a good quality reprint. A good-quality photocopy would also
                  be acceptable provided it is legible enough for us to transcribe. Please include as much information as you have
                  about the photo or image, for example, names, location, date, cemetery or memorial. If you are using a digital camera
                  to photograph a grave or memorial, please use the camera's highest resolution and send the image to us in JPEG, or if

                  possible, TIFF file format.
                                    </p>
                <p>
                  <Link to="/digitizationguide">Read our digitisation guidelines</Link> for help creating digital copies of your photos and documents.
                                    </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>

      <Row style={{ padding: "0", margin: "0", background: "black", color: "white" }}>
        <Col style={{ height: "79vh", alignItems: "center", display: "flex" }}>
          <div className="body__wrapper">
            <Row>
              <Col md={7} style={{ paddingLeft: "0" }}>
                <div >
                  <h5 className={"participate__header"}>Can I help?</h5>
                  <p style={{ fontSize: 15 }}>
                    Nigeria Biafra War Memories has a small volunteer team and sadly are unable to cover much ground as we hope. We are therefore asking for help from members of the public to assist us with cases where we need further assistance.
                                    </p>
                </div>
              </Col>
              <Col md={5} style={{ alignItems: "center", display: "flex", justifyContent: "center" }}>
                {/* <Stack>
                  <Link to="/searchperson">
                    <CompoundButton secondaryText="Choose how to contribute to this project"
                      style={{ background: '#FFFFFF', color: '#000000', width: 281 }}
                      styles={{ secondaryText: { color: '#000000' } }}>
                      Contact Us
                                    </CompoundButton>
                  </Link>
                </Stack> */}

                <Stack>
                  <a href="mailto:kedu@centreformemories.org">
                    <CompoundButton secondaryText="Choose how to contribute to this project"
                      style={{ background: '#FFFFFF', color: '#000000', width: 281 }}
                      styles={{ secondaryText: { color: '#000000' } }}>
                      Contact Us
                                    </CompoundButton>
                  </a>
                </Stack>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <div style={{ height: "79.4vh", display: "flex", alignItems: "center", width: "89.5%", margin: "auto" }}>
        <Row style={{ padding: "0", margin: "0" }}>
          <Col md={5} style={{ display: "flex", alignItems: "center", paddingLeft: "0" }}>
            <div style={{ width: "80%" }}>
              <img src={participate1} alt="mask off 1" width="100%" />
            </div>
          </Col>
          <Col md={7}>
            <div style={{ marginBottom: "12vh" }}>
              <h5 className={"participate__header"}>Historical Information and Materials</h5>
              <div style={{ fontSize: "15px" }}>
                <small>
                  <p>
                    If you have physical artefacts or materials associated with the Nigeria - Biafra war, please visit our FAQ for further
                    information on what you can do with these.
                                    </p>
                </small>
              </div>
            </div>
            {/* <Btn name="FAQ" text="Frequently Asked Questions " color="white" bgColor="black" /> */}
            <Stack>
              <Link to="/faq">
                <CompoundButton secondaryText="Frequently Asked Questions"
                  style={{ background: '#000000', color: '#FFFFFF', width: 281 }}
                  className="hoverLink"

                >
                  FAQ
                                    </CompoundButton>
              </Link>
            </Stack>

          </Col>
        </Row>
      </div>

      <Row style={{ padding: "0", margin: "0", background: "black" }}>
        <div className="body__wrapper">
          <Row>
            <Col md={7} style={{ color: "white", height: "79.4vh", display: "flex", alignItems: "center" }}>
              <div style={{ width: "80%" }}>
                <h5 className={"participate__header"}>Donate</h5>
                <p style={{ fontSize: "15px", lineHeight: "24px", marginBottom: "12vh" }}>
                  If you would like to support with a financial donation then please call us or send an email to discuss                                </p>

                {/* <Btn name="FAQ" text="Frequently Asked Questions " color="black" bgColor="white" /> */}
                <Stack>
                  <a href="mailto:kedu@centreformemories.org">
                    <CompoundButton
                      secondaryText="Let us show you how you can help"
                      style={{
                        background: "#FFFFFF",
                        color: "#000000",
                        width: 281
                      }}
                    >
                      Contact Us
                  </CompoundButton>
                  </a>
                </Stack>
              </div>
            </Col>

            <Col md={5} style={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
              <div style={{ width: "80%" }}>
                <img src={participateImg} alt="mask off" width="100%" />
              </div>
            </Col>
          </Row>
        </div>
      </Row>

      <Row style={{ padding: "0", margin: "0" }}>
        <div className="body__wrapper">
          <Row>
            <Col md={4} style={{ marginTop: "19.3vh" }}>
              <h3 className={"participate__header"}>Contribution<br /> of Content</h3>
              <div style={{ width: "80%" }}>
                <p style={{ fontSize: "15px", lineHeight: "24px" }}>
                  By contributing any material via this website, you are confirming that:
                            </p>
                <p style={{ fontSize: "15px", lineHeight: "24px" }}>
                  Your contribution is your original work and you are fully entitled to grant to the Nigeria - Biafra War Memories team the rights to your materials;
                            </p>
                <p style={{ fontSize: "15px", lineHeight: "24px" }}>
                  You grant the Nigeria - Biafra War Memories team a worldwide, royalty-free, non-exclusive, license to reproduce, modify or use that material or otherwise exercise any right that any holder of the copyright in that material may hold for the duration of the applicable copyright;
                            </p>
                <p style={{ fontSize: "15px", lineHeight: "24px" }}>
                  Contributed material must not include material that infringes any intellectual property or privacy rights, breaches any suppression order or any other law, or be purposefully inflammatory.
                            </p>

                <p style={{ fontSize: "15px", lineHeight: "24px" }}>
                  The Nigeria - Biafra War Memories team has the right to modify or remove user-contributed material at its sole discretion and without notification.
                            </p>
              </div>
            </Col>
            <Col md={8}>
              <Row>
                <Col md={12} style={{ paddingLeft: "10%", marginTop: "19.3vh" }}>
                  <h2 className={"participate__header"} style={{ marginBottom: "2vh" }}>Privacy</h2>
                  <p style={{ fontSize: "15px", lineHeight: "24px", letterSpacing: "0.01em" }}>
                    You may browse this website without providing any personal information.  Where you voluntarily provide
                    personal information the NBWM team will only use that information for the specific purpose stated.  In
                    providing this personal information you agree to its collection and our use of that information for the
                    purpose stated at the time of its collection.  The NBWM team will keep any such information secure and will
                    only disclose it to third parties as specified in these terms of use.
                                    </p>
                </Col>
                <Col md={12} style={{ paddingLeft: "10%", marginTop: "4vh" }}>
                  <h2 className={"participate__header"} style={{ marginBottom: "2vh" }}>Cookies</h2>
                  <p style={{ fontSize: "15px", lineHeight: "24px", letterSpacing: "0.01em" }}>
                    The website has been designed to automatically issue a cookie to visitors.  This is used to provide information
                    to enable us to analyze overall visitor activities on the website and to provide a site more tailored to your needs
                    and interests.  We do not use cookies to collect personal information, nor do we link non-personal information stored
                    in cookies with personal information about you.
                                    </p>
                </Col>
                <Col md={12} style={{ paddingLeft: "10%", marginTop: "19.3vh" }}>
                  <h2 className={"participate__header"} style={{ marginBottom: "8vh" }}>Giving your Consent</h2>
                  <p style={{ fontSize: "24px", lineHeight: "40px", letterSpacing: "0.01em" }}>
                    When submitting a form, or making a payment, I agree to my details being used by the NBWM team for this project.
                    I understand my data will be held securely and will not be distributed to other parties except as required by law.
                    I have a right to change or access my information at any time.
                                    </p>
                </Col>
                <Col md={12} style={{ paddingLeft: "10%", marginTop: "4vh" }}>
                  <h2 className={"participate__header"} style={{ marginBottom: "2vh" }}>Disclaimer</h2>
                  <p style={{ fontSize: "15px", lineHeight: "24px", letterSpacing: "0.01em" }}>
                    We disclaim and exclude all liability for any claim, loss, demands or damages of any kind whatsoever
                    (including for negligence) arising out of or in connection with the use of either this website or the information,
                    content or materials included on this site or on any website to which it is linked.
                                    </p>
                </Col>
                <Col md={12} style={{ paddingLeft: "10%", marginTop: "4vh", marginBottom: "4vh" }}>
                  <h2 className={"participate__header"} style={{ marginBottom: "2vh" }}>Contact Us</h2>
                  <p style={{ fontSize: "15px", lineHeight: "24px", letterSpacing: "0.01em" }}>
                    Please contact <a href="mailto:kedu@centreformemories.org">kedu@centreformemories.org</a> if you have any questions about these terms of use.
                                    </p>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </Row>

      <Row style={{ padding: "0", margin: "0", background: "black" }}>
        <div className="body__wrapper">
          <Row>
            <Col md={5} style={{ display: "flex", alignItems: "center" }}>
              <div style={{ width: "80%", display: "flex", alignItems: "center" }}>
                <img src={participate2} alt="mask off" width="100%" />
              </div>
            </Col>

            <Col md={7} style={{ color: "white", height: "79.4vh", display: "flex", justifyContent: "cener", alignItems: "center" }}>
              <div >
                <h5 className={"participate__header"} style={{ marginBottom: "8vh" }}>Need more help?</h5>
                <p style={{ fontSize: "15px", lineHeight: "24px", marginBottom: "11.1vh" }}>
                  Do you have questions about how best to contribute to this project? Would you like to request the creation of a new record?
                                Contact our team at the Centre for Memories with the button below.</p>

                <Stack>
                  <a href="mailto:kedu@centreformemories.org">
                    <CompoundButton secondaryText="Choose how to contribute to this project"
                      style={{ background: '#FFFFFF', color: '#000000', width: 281 }}
                      styles={{ secondaryText: { color: '#000000' } }}>
                      Contact Us
                      </CompoundButton>
                  </a>
                </Stack>

              </div>
            </Col>
          </Row>
        </div>
      </Row>

            <div className="body__wrapper">
                <Row>
                    <Col style={{ padding: "21vh 1vw" }} >
                        <h5 className={"participate__header"} style={{ marginBottom: "2vh" }}>Privacy Statement</h5>
                        <p className="privary_statement">
                            NBWM collects information and data from publicly available official sources and
                            contributions from third parties to create a national biographical database recording
                            and preserving the memories and honouring
                            the lives of Nigerians who served or were affected by the war.
                        </p>
            <p className="privary_statement">
              We collect data on the service experiences of people still living or dead.
              Participating and contributing data to NBWM is entirely voluntary.
               Individuals featured on having the right to ask for a printed or digital copy of any information we hold about them.
               They may also ask to have it removed or corrected if wrong.
                        </p>
            <p className="privary_statement">
              If you would like a copy of your information or have it removed or corrected,
                        please contact us +234 11 22 33 00 or send us an email on <a href="mailto:kedu@centreformemories.org">kedu@centreformemories.org</a>
            </p>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default Participate;