import React from "react";
import { Row, Col } from "react-bootstrap";

import "../stories.css";
import { Stack, Dropdown, ResponsiveMode } from "office-ui-fabric-react";
import { initializeIcons } from "@uifabric/icons";
import NoImage from "../../../assests/image/no-image.webp";
import { CompoundButton } from "office-ui-fabric-react/lib";
import { Link } from "react-router-dom";
import Loader from "../../../assests/image/loading.gif";
import AlertCard from "../../../components/alertCard/alertCard";
import AuthorRating from "../../../components/rating/authorRating";
import withStoriesApi from "../storiesUtils";
initializeIcons();

const Stories = props => {
  var {load, stories, handleStory, ReportImg, MoveToNextPage,MoveToPrevious } = props;
  {window.scrollTo(0, 0)}

  const renderStories = () => {
    return stories.liveData.map((s, index) => {
      return (
        <Row className={"stories__row__spacing"} key={index}>
          <Col
            md={9}
            style={{
              padding: "0",
              margin: "0",
              fontFamily: "Segoe UI"
            }}
          >
            <p
              className={"stories__big__header"}
              onClick={() => handleStory(s.story.id)}
            >
              {s.story.title}
            </p>
            <div
              style={{ fontSize: "15px" }}
              id="con"
              dangerouslySetInnerHTML={{
                __html:
                  s.story.content.length < 200
                    ? s.story.content
                    : s.story.content.slice(0, 200) + "..."
              }}
            ></div>

            <div style={{ fontSize: "15px", fontWeight: "300" }}>
              Testimonies, Survivors
            </div>
            <div style={{ fontSize: "12px", fontWeight: "bold" }}>
              By {s.story.author}
              {"   "}
              <AuthorRating />
            </div>
          </Col>

          <Col md={3}>
            <div>
              <img
                src={
                  s.picture_url.length === 0
                    ? NoImage
                    : s.picture_url[0].picture_url
                }
                alt=""
                width="213"
                height="186"
                className="storiesImg"
                style={{ border: "1px solid lightgray" }}
              />
              <i
                className="reportText"
                style={{ marginLeft: "3.5rem", cursor: "pointer" }}
                onClick={ReportImg}
              >
                Report this photo
              </i>
            </div>
          </Col>
        </Row>
      );
    });
  };

  const renderPopularStories = () => {
    return stories.liveData.slice(0, 10).map((s, index) => {
      return (
        <div className={"stories__div__spacing"} key={"popular" + index}>
          <h4
            className={"stories__small__header"}
            onClick={() => handleStory(s.story.id)}
          >
            {s.story.title}
          </h4>
          <div
            className="popularContentFont"
            style={{ fontSize: "15px" }}
            id="con"
            dangerouslySetInnerHTML={{
              __html:
                s.story.content.length < 140
                  ? s.story.content
                  : s.story.content.slice(0, 140) + "..."
            }}
          ></div>
          <div style={{ fontSize: 15, fontWeight: "300" }}>
            Category, Category
          </div>
          <div style={{ fontSize: 12, fontWeight: "bold" }}>
            By {s.story.author} {"   "}
            <AuthorRating />
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <AlertCard message={props.error} error={true} />
      <div className="body__wrapper">
        <Row style={{ margin: 0 }}>
          <Col
            md={12}
            style={{
              margin: "17.5vh 0 0",
              fontWeight: "bold",
              fontWeight: "bold",
              padding: 0
            }}
          >
            <h3 className={"storeis__main__header"}>Stories</h3>
          </Col>
        </Row>
        <div>
          <Row style={{ margin: 0 }}>
            <Col md={9} style={{ padding: "0px 130px 0px 0px" }}>
              {load ? (
                <div className="d-flex justify-content-center align-items-center">
                  <img src={Loader} alt="loader" />
                </div>
              ) : stories && stories.totalresult === 0 ? (
                <div className="d-flex justify-content-center align-items-center">
                  <h4>No stories yet</h4>
                </div>
              ) : (
                stories.totalresult > 0 && renderStories()
              )}
            </Col>
            <Col md={3} style={{ fontFamily: "Segoe UI" }} id="conn">
              <div>
                <h3
                  className={"stories__small__header"}
                  style={{ marginBottom: "7.1vh" }}
                >
                  {stories && stories.data.length === 0 ? "" : "Popular Stories"}
                </h3>
                {load? (
                  <div className="d-flex justify-content-center align-items-center">
                    <img src={Loader} alt="loader" />
                  </div>
                ) : stories.totalresult > 0 && stories.totalresult === 0 ? (
                  <div className="d-flex justify-content-center align-items-center">
                    <h4>No stories yet</h4>
                  </div>
                ) : (
                  renderPopularStories()
                )}
              </div>
            </Col>
          </Row>
        </div>

        <Row style={{ margin: 0, color: "#605E5C" }}>
          <Col md={12} className="d-flex justify-content-center my-4">
          {stories.totalresult > 4 ? (
            <div className="d-flex justify-content-space-between align-items-baseline">
              {
                (stories.page > 1) &&
                <div
                  onClick={() => MoveToPrevious(stories.page - 1)}>
                  <span className="fas fa-angle-double-left clickIt"></span>
                  <span className="fas fa-angle-left ml-3 clickIt"></span>
                </div>
              }
              <span className="mx-3">
                <Dropdown
                  placeholder="pages"
                  defaultSelectedKey={stories.page}
                  responsiveMode={ResponsiveMode.large}
                  options={Array(Math.ceil(stories.totalresult / stories.size))
                    .fill(1)
                    .map((x, index) => {
                      return { key: index + 1, text: index + 1 };
                    })}
                  styles={{ dropdown: { width: 100 } }}
                  onChange={(e, index) => MoveToNextPage(index.key)}
                  onSelect={(e, key) =>{}}
                />
              </span>
              <span style={{ fontSize: 14 }}>
                {" "}
                of {stories && Math.ceil(stories.totalresult / stories.size)} &nbsp;pages
              </span>&nbsp;&nbsp;

              {(stories.totalresult > stories.page * stories.size) &&
                <div
                  onClick={() => MoveToNextPage(stories.page + 1)}>
                  <i className="fas fa-angle-right mr-3 clickIt"></i>
                  <i className="fas fa-angle-double-right clickIt"></i>
                </div>
              }
            </div>

          ) : null}


          </Col>
        </Row>

        <Row style={{ margin: 0, marginTop: "10%" }}>
          <div style={{ width: "50%" }}>
            <h3 className="addHint">
              Have an experience or story about the war to share?
            </h3>
            <p className="subtleText">
              Do you have an experience or story you would like to share with
              the community. You can contribute using the button below.
            </p>
            <Link to="/addstory">
              <CompoundButton
                secondaryText="Choose how to contribute to the project"
                className="hoverLink mt-4"
                style={{
                  background: "#000000",
                  color: "#FFFFFF",
                  width: 281,
                  marginBottom: "8rem"
                }}
              >
                Contribute
              </CompoundButton>
            </Link>
          </div>
        </Row>
      </div>
    </div>
  );
};

export default withStoriesApi(Stories);

{
  /* <div style={{ width: "20%" }}>
              <Stack tokens={stackTokens}>
                <Drop
                  styles={{
                    dropdown: { width: 255 },
                    dropdownItemsWrapper: { height: 200 }
                  }}
                  // placeHolder="Select a conflict"
                  options={conflictTypes}
                  defaultSelectedKey={0}
                />
              </Stack>
            </div> */
}
