import React from "react";
import logo from "../../assests/image/logo.webp";

const MFooter = () => {
  return (
    <div className="footerBackground">
      <div className="row" style={{ margin: 0 }}>
        <div className="col-12 mt-5">
          <img
            src={logo}
            alt="logo"
            className="footerLogo"
            width="84"
            height="83"
          />
        </div>
      </div>

      <div className="row" style={{ margin: 0 }}>
        <div className="col-12 mt-4">
          <p className="footerTxt">
            The Nigeria - Biafra War Memories is a repository of stories,
            interviews, and memorabilia sourced from a community of survivors.
          </p>
        </div>
      </div>

      <div className="row" style={{ margin: 0 }}>
        <div className="col-12">
          <p className="footerSubTitle my-4">CONTACTS</p>
          <address>
            <div>
              <small className={"footer__text__font"}>
                No. 2 Agwu Street, off Umuoji
                <br /> Street, Independence Layout
                <br /> Enugu-Enugu, Nigeria
              </small>
            </div>
            <div>
              <small
                style={{ margin: 0, padding: 0 }}
                className={"footer__text__font"}
              >
              </small>
            </div>
            <div>
              <small
                style={{
                  color: "#9B9B9B",
                  margin: 0,
                  padding: 0,
                  textDecoration: "underline"
                }}
                className={"footer__text__font"}
              >
                {/* info@bwmm.com */}
                <a href="mailto:kedu@centreformemories.org">
                  kedu@centreformemories.org
                </a>
                <br />
              </small>
            </div>
            <div>
              <small
                style={{
                  color: "#9B9B9B",
                  margin: 0,
                  padding: 0,
                  textDecoration: "underline"
                }}
                className={"footer__text__font"}
              >
                <a href="http://centreformemories.org">
                  http://centreformemories.org
                </a>
                <br />
                {/* http://centreformemories.com */}
              </small>
            </div>
          </address>
        </div>
      </div>

      <div className="row" style={{ margin: 0 }}>
        <div className="col-12">
          <p className="footerSubTitle my-4">CENTRE FOR MEMORIES</p>
          <div className="pt-2">
            <small className={"footer__text__font"}>
              The Centre For Memories (CFM) is the leading hub for Igbo history,
              culture, and excellence.
            </small>
          </div>
        </div>
      </div>

      <div className="row" style={{ margin: 0 }}>
        <div className="col-12 pt-4 pb-5">
          <p className="copyrightTxt">© 2020 Nigeria - Biafra War Memories</p>
        </div>
      </div>
    </div>
  );
};

export default MFooter;
