import React from "react";
import Flower from "../../assests/image/tributeFlower.png";
import Photo from "../../assests/image/researchImg.webp";
import "./resultCard.css";
import { Link, withRouter } from "react-router-dom";

const ResultCard = props => {
  const OpenPerson = () => {
    props.history.push({ pathname: `/profile/${props.id}` });
  };
  return (
    <div onClick={OpenPerson}>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-3 resultImg d-flex justify-content-center align-items-center">
          <div className="">
            <div>
              <img src={props.pic} alt="result" />
              {/* <Link to="/report">
                <i style={{ padding: "2vw" }}>Report this photo</i>
              </Link> */}
            </div>
          </div>
        </div>
        <div className="col-7 cardMainSec">
          <div className="typeText">Military</div>
          <h2 className="resultName">{props.name}</h2>
          <div>
            <span>
              <i className="fa fa-link"></i>Starvation Galore
            </span>
            <span>
              <i className="fa fa-link"></i>I Buried My Son
            </span>
            <span>Attachment: 12</span>
          </div>
          <div>
            <p className="statusText mt-3">Status: Alive</p>
          </div>
        </div>
        <div className="col-2 d-flex justify-content-center align-items-center">
          <span>
            <img src={Flower} alt="tribute" />
            40k
          </span>
        </div>
      </div>
    </div>
  );
};

export default withRouter(ResultCard);
