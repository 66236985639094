import React from "react";
import { Row, Container, Col } from "react-bootstrap";
import "./dHome.css";
import ImageOne from "../../../assests/image/Mask.webp";
import ImageTwo from "../../../assests/image/Mask_1_.webp";
import ImageThree from "../../../assests/image/Mask_2_.webp";
import ImageFour from "../../../assests/image/Bitmap.webp";
import ImageFive from "../../../assests/image/image_home.webp";
import { Link } from "react-router-dom";
import { Stack, CompoundButton, IStackTokens } from "office-ui-fabric-react";

const home = () => {
  return (
    <Container fluid={true} style={{ margin: 0, padding: 0 }}>
      <Row style={{ padding: 0, margin: 0 }}>
        <Col md={12} className={"home__top__col"}>
          <div className="body__wrapper">
            <Row
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center"
              }}
            >
              <Col md={8} className={"home__margin__left__res"}>
                <div>
                  <h1 className={"home__main__header"}>
                    Welcome to the Nigeria - Biafra War Memories{" "}
                  </h1>
                  <p className={"home__top__writeup"}>
                    The Nigeria - Biafra War Memories is a repository of war
                    stories, interviews, and memorabilia sourced from the
                    community of survivors.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>

        <Col md={12} className={"home__mid__one"}>
          <div className="body__wrapper">
            <Row>
              <Col
                md={4}
                sm={4}
                style={{ padding: 0 }}
                className="stage_wrapper"
              >
                <div>
                  <h1
                    style={{ marginBottom: "9vh" }}
                    className={"hear__story__header"}
                  >
                    Hear Their <br />
                    Stories
                  </h1>
                  <Link to="/stories">
                    <CompoundButton
                      secondaryText={"Personal accounts of pain and survival"}
                      style={{ width: 281 }}
                    >
                      More Stories
                    </CompoundButton>
                  </Link>
                </div>
              </Col>
              <Col md={8} sm={8} className="stage_wrapper">
                <Row>
                  <Col
                    md={4}
                    sm={4}
                    style={{ textAlign: "center", paddingLeft: "49.82px" }}
                  >
                    <div style={{ width: "100%" }}>
                      <img alt="image" src={ImageOne} width="100%" />
                    </div>
                    <div className={"name__author__story"}>
                      Ositadimma
                      <br /> Obiageli
                    </div>
                    <div
                      style={{
                        borderBottom: "2px solid #707070",
                        padding: "18px"
                      }}
                    >
                      "The war was terrible. I never want to witness
                      something like that again"
                    </div>
                  </Col>
                  <Col
                    md={4}
                    sm={4}
                    style={{ textAlign: "center", paddingLeft: "49.82px" }}
                  >
                    <div style={{ width: "100%" }}>
                      <img alt="image" src={ImageTwo} width="100%" />
                    </div>
                    <div className={"name__author__story"}>
                      Ositadema
                      <br />
                      Jude
                    </div>
                    <div
                      style={{
                        borderBottom: "2px solid #707070",
                        padding: "18px"
                      }}
                    >
                      “To understand the war, it is important to learn about the
                      history of Nigeria”
                    </div>
                  </Col>
                  <Col
                    md={4}
                    sm={4}
                    style={{ textAlign: "center", paddingLeft: "49.82px" }}
                  >
                    <div style={{ width: "100%" }}>
                      <img alt="image" src={ImageThree} width="100%" />
                    </div>
                    <div className={"name__author__story"}>
                      Ositadema
                      <br />
                      Jude
                    </div>
                    <div
                      style={{
                        borderBottom: "2px solid #707070",
                        padding: "18px"
                      }}
                    >
                      “We’re happy to finally tell our stories. Nigerians need
                      to know their history”
                    </div>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
        <Col md={12} className={"home__mid__two"}>
          <div className="body__wrapper">
            <Row>
              <Col
                md={6}
                sm={5}
                style={{
                  paddingLeft: 0,
                  display: "flex"
                  // alignItems: "center"
                }}
              >
                <div className="home__img__wrapper">
                  <img alt="photo" src={ImageFour} width="100%" />
                </div>
              </Col>
              <Col md={6} sm={7}>
                <div style={{ float: "right" }}>
                  <h1 style={{ marginBottom: "4.2vh" }}>
                    Tell your stories from the War
                  </h1>
                  <p>
                    There are many ways you can contribute to the Nigeria-Biafra War Memories Project.
                  </p>
                  <p style={{ marginBottom: "6vh", fontFamily: "Segoe UI" }}>
                    {/* You can tell your story (<span className="text__decorate__italic">the museum collects testimonies from survivors, perpetrators,
                                        rescuers and elders</span>), donate materials (<span className="text__decorate__italic">photos, identification cards, lost relatives'
                                        belongings, documents and much more</span>), volunteer(<span className="text__decorate__italic">the museum welcomes volunteers, interns
                                        and research affiliates to join our team in order to contribute and learn about the work
                                        we do</span>) and support us with financial donations or expertise sharing. */}
                    You can tell your story (
                    <span className="text__decorate__italic">
                      the museum collects testimonies from survivors,
                      perpetrators, rescuers, and elders
                    </span>
                    ), donate materials (
                    <span className="text__decorate__italic">
                      photos, identification cards, lost relatives' belongings,
                      documents, and much more
                    </span>
                    ), volunteer(
                    <span className="text__decorate__italic">
                      the museum welcomes volunteers, interns and research
                      affiliates to join our team to contribute and
                      learn about the work we do
                    </span>
                    ) and support us with financial donations or expertise
                    sharing.
                  </p>
                  <Link to="/participate" className="hoverLink">
                    {/* <Btn name="How to Partcipate" text="Stories, photos, artefacts" color="white" bgColor="black" /> */}
                    <span style={{ width: 237, color: "#FFFFFF" }}>
                      <CompoundButton
                        secondaryText={"Stories, photos, artefacts"}
                        style={{
                          background: "#000000",
                          color: "#FFFFFF",
                          width: 281
                        }}
                        className="hoverLink"
                      >
                        How to Partcipate
                      </CompoundButton>
                    </span>
                  </Link>
                </div>
              </Col>
            </Row>
          </div>
        </Col>

        <Col md={12} className={"home__mid__three"}>
          <div className="body__wrapper">
            <Row>
              <Col
                md={6}
                sm={7}
                style={{
                  display: "flex",
                  alignItems: "center",
                  paddingLeft: 0,
                  paddingRight: 0
                }}
              >
                <div>
                  <h1 style={{ marginBottom: "4.2vh" }}>
                    Frequently Asked Questions
                  </h1>
                  <p style={{ marginBottom: "6  vh" }}>
                    Visit our FAQ section for fast answers to questions most
                    users might have using the memorabilia.
                  </p>
                  <Link to="/faq">
                    <CompoundButton
                      secondaryText={`Frequently Asked Questions`}
                      style={{ color: "#000000", width: 281 }}
                    >
                      FAQ
                    </CompoundButton>

                    {/* <Btn name="FAQ" text="Frequently Asked Questions " color="black" bgColor="white" /> */}
                  </Link>
                </div>
              </Col>
              <Col md={6} sm={5}>
                <div style={{ float: "right" }} className="home__img__wrapper">
                  <img alt="photo" src={ImageFive} width="100%" />
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default home;
