import React from "react";
import about2 from "../../../assests/image/abtImg2.webp";
import about3 from "../../../assests/image/abtImg3.webp";
import about4 from "../../../assests/image/abtImg4.webp";
import about5 from "../../../assests/image/abtImg5.webp";
import { Link } from "react-router-dom";
import '../../home/desktop/dHome.css'
import "./mAbout.css";
import { CompoundButton, Stack } from "office-ui-fabric-react";
const MAbout = () => {
  const renderImg = Image => {
    return (
      <div>
        <img src={Image} alt="tell a story" className="tellImg mb-5" />
      </div>
    );
  };
  return (
    <div>
      <section className="row sectionBgWhite">
        <div className="col-12 mhome_background">
          <h1 className="titleMobWhite ml-3 mt-4 mb-5 topSpace">
            About the Nigeria - <br />
            Biafra War Memories <br />
            Project{" "}
          </h1>
          <p className="aboutTxt  ml-3">
            Nigeria - Biafra War Memories Project (NBWM) seeks to create, with
            your help, a most comprehensive understanding of the condition of
            war memorials. It will help us plan for the future conservation of
            war memorials to ensure they are fit to commemorate our loved ones
            recorded upon them.
          </p>
        </div>
      </section>

      <section className="row sectionBgWhite pb-4">
        <div className="col-12">
          <h3 className="titleMobBlack mt-5 mb-4">
            A Record of Those Who were involved in the Nigeria - Biafra Civil
            War
          </h3>
          {renderImg(about3)}
          <p className="pTxt">
            This is a national biographical database initiative that allows
            researchers, enthusiasts, and veterans and their families to
            explore, contribute to, and share the records and stories of those
            who served or were caught in-between the events of the war. Become
            part of the NBWM volunteer community at your own pace, in your own
            time. You can visit your local war memorials, take photographs and
            report on their condition on War Memorials Online.
          </p>
        </div>
      </section>

      <section className="row sectionBgBlack pb-4">
        <div className="col-12">
          <h3 className="titleMobWhite mt-5 mb-4">
            Your Place for Stories and Commemoration
          </h3>
          {renderImg(about2)}
          <p className="contentTxt">
            The information on this website is added and edited by members of
            the public. We invite you to start adding information about your
            local war memorials. You can participate by going to{" "}
            <Link to="/personsearch">Person Search</Link> and looking up data on
            our records and adding to it. We are very grateful for all
            contributions, but photographs and condition reports are especially
            helpful.
          </p>
        </div>
      </section>

      <section className="row sectionBgWhite pb-4">
        <div className="col-12">
          <h3 className="titleMobBlack mt-5 mb-4">
            Join the Volunteer Community
          </h3>
          {renderImg(about4)}
          <p className="pTxt">
            Become part of the Nigeria - Biafra War Memories volunteer community
            at your own pace, in your own time. You can visit your local war
            memorials, take photographs and report on their condition on this
            portal.
          </p>
          <div className="mt-5 mb-4 pb-4">
            <a href="mailto:kedu@centreformemories.org">
              <CompoundButton
                secondaryText="Choose how to contribute to this project"
                style={{ background: "#000000", color: "#FFFFFF", width: 281 }}
                styles={{ secondaryText: { color: "#FFFFFF" } }}
                className="hoverLink"
              >
                Contact Us
              </CompoundButton>
            </a>
          </div>
        </div>
      </section>

      <section className="row sectionBgBlack pb-5">
        <div className="col-12">
          <h3 className="titleMobWhite mt-5 mb-4">Help Grow Our Records</h3>
          {renderImg(about5)}
          <p className="contentTxt">
            We need your help to ensure the records are properly curated. Thanks
            to your overwhelming support we currently have a few records and its
            growing by the day. You can browse the current records{" "}
            <Link to="/searchperson">here</Link> and upload your own content
            including photos and condition reports to help create a complete
            picture of all war memorials across the board. If you cannot find a
            record or memorial then we would be most grateful if you could add
            the details.
          </p>
        </div>
      </section>
    </div>
  );
};
export default MAbout;
