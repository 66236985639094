import React from "react";
import { Switch, Route, BrowserRouter } from "react-router-dom";
import Home from "./pages/home/home"; //changed home to tablet view
import About from "./pages/about/about";
import Participate from "./pages/participate/participate";
import Stories from "./pages/stories/stories";
import Research from "./pages/research/research";
import SearchPerson from "./pages/personSearch/index";
import DigitalGuideline from "./pages/digitalGuideline/index";
import Faq from "./pages/faq/faq";
import AddPerson from "./pages/addPerson/addPerson";
import Layout from "./components/layout/layout";
import AddStory from "./pages/addStory/addStory";
import SingleStory from "./pages/stories/singleStory";
import Profile from "./pages/profile/profile";
import Report from "./pages/report/report";
import MobileLayout from './components/layout/mobileLayout';
import TabletLayout from './components/layout/tabletLayout';
import { MOBILE, TABLET } from './resolution'

const MyRoute = ({ Component, path, resolution, exact }) => {
  var LayoutToUse = resolution === MOBILE ? MobileLayout : resolution === TABLET ? TabletLayout : Layout;
  // alert(resolution);
  return (
    <Route
      exact={exact || true}
      path={path}
      render={props => {
        return (
          <LayoutToUse>
            <Component {...props} resolution={resolution} />
          </LayoutToUse>
        );
      }}
    />
  );
};
const Routes = ({ resolution, changed }) => {
  return (
    <BrowserRouter>
      <Switch>
        <MyRoute path="/" Component={Home} resolution={resolution} exact />
        <MyRoute
          path="/about"
          Component={About}
          resolution={resolution}
          exact
        />
        <MyRoute
          path="/participate"
          Component={Participate}
          resolution={resolution}
          exact
        />
        <MyRoute
          path="/stories"
          Component={Stories}
          resolution={resolution}
          exact
        />
        <MyRoute
          path="/research"
          Component={Research}
          resolution={resolution}
          exact
        />
        <MyRoute
          path="/searchperson"
          Component={SearchPerson}
          resolution={resolution}
          exact
        />
        <MyRoute
          path="/digitizationguide"
          Component={DigitalGuideline}
          resolution={resolution}
          exact
        />
        <MyRoute path="/faq" Component={Faq} resolution={resolution} exact />
        <MyRoute
          path="/addperson"
          Component={AddPerson}
          resolution={resolution}
          exact
        />
        <MyRoute
          path="/addstory"
          Component={AddStory}
          resolution={resolution}
          exact
        />
        <MyRoute
          path="/story/:id"
          Component={SingleStory}
          resolution={resolution}
          exact
        />
        <MyRoute
          path="/profile/:id"
          Component={Profile}
          resolution={resolution}
          exact
        />
        <MyRoute
          path="/report"
          Component={Report}
          resolution={resolution}
          exact
        />
      </Switch>
    </BrowserRouter>
  );
};

export default Routes;
