import React from "react";
import DesktopProfile from "./desktop/dProfile";
import MobileProfile from "./mobile/mProfile";
import TabletProfile from "./tablet/tProfile";
import { MOBILE, TABLET } from "../../resolution";

export default props => {
  if (props.resolution === MOBILE) {
    return <MobileProfile props={props} />;
  } else if (props.resolution === TABLET) {
    return <TabletProfile props={props} />;
  } else {
    return <DesktopProfile props={props} />;
  }
};
