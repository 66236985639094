import React from "react";
import ImageOne from "../../../assests/image/Mask.webp";
import ImageTwo from "../../../assests/image/Mask_1_.webp";
import ImageThree from "../../../assests/image/Mask_2_.webp";
import ImageFour from "../../../assests/image/Bitmap.webp";
import { Link } from "react-router-dom";
import { Carousel } from "react-bootstrap";
import { Stack, CompoundButton, IStackTokens } from "office-ui-fabric-react";
import ImageFive from "../../../assests/image/image_home.webp";
const MHome = props => {
  return (
    <div>
      <section className="row sectionBgWhite">
        <div className="col-12 mhome_background">
          <h3 className="welText ml-3">
            Welcome to the Nigeria - Biafra War Memories
          </h3>
          <p className="subWelTxt ml-3 mt-4 mb-5">
            The Nigeria - Biafra War Memories is a repository of war stories,
            interviews, and memorabilia sourced from a community of survivors
          </p>
        </div>
      </section>

      <section className="row sectionBgBlack">
        <div className="col-12">
          <h4 className="titleMobWhite mt-5 mb-4">Hear Their Stories</h4>
          <Carousel
            nextIcon={<span className="fa fa-chevron-right whiteIcon"></span>}
            prevIcon={<span className="fa fa-chevron-left whiteIcon"></span>}
            interval={null}
          >
            <Carousel.Item>
              <div className="d-flex justify-content-center">
                <span className="carouselItem">
                  <img
                    src={ImageOne}
                    alt="popular stories"
                    className="popularStory mb-3"
                  />
                  <p className="authorTitle text-center pb-3">
                    Ositadimma Obiageli
                  </p>
                  <p className="storyQuote pb-3">
                    “The war was terrible. I never want to witness something
                    like that again”
                  </p>
                </span>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="d-flex justify-content-center">
                <span className="carouselItem">
                  <img
                    src={ImageTwo}
                    alt="popular stories"
                    className="popularStory mb-3"
                  />
                  <p className="authorTitle text-center pb-3">
                    Ositadimma Jude
                  </p>
                  <p className="storyQuote pb-3">
                    “We’re happy to finally tell our stories. Nigerians need to
                    know their history”
                  </p>
                </span>
              </div>
            </Carousel.Item>
            <Carousel.Item>
              <div className="d-flex justify-content-center">
                <span className="carouselItem">
                  <img
                    src={ImageThree}
                    alt="popular stories"
                    className="popularStory mb-3"
                  />
                  <p className="authorTitle text-center pb-3">
                    Ositadimma Jude
                  </p>
                  <p className="storyQuote pb-3">
                    “To understand the war, it is important to learn about the
                    history of Nigeria”
                  </p>
                </span>
              </div>
            </Carousel.Item>
          </Carousel>
        </div>
      </section>

      <section className="row sectionBgWhite">
        <div className="col-12">
          <h4 className="titleMobBlack mt-5 mb-4">
            Tell Your Stories
            <br />
            from the War
          </h4>
          <div className="my-4">
            <img src={ImageFour} alt="tell your story" className="tellImg" />
          </div>
          <p className="tellStoryTxt">
            There are many ways you can contribute to the Nigeria - Biafra War
            Memories. <br />
            <br />
            You can tell your story (the museum collects testimonies from
            survivors, perpetrators, rescuers and elders), donate materials
            (photos, identification cards, lost relatives' belongings,
            documents, and much more), volunteer(the museum welcomes volunteers,
            interns, and research affiliates to join our team in order to
            contribute and learn about the work we do) and support us with
            financial donations or expertise sharing.
          </p>
          <div className="my-5 pb-3">
            <Link to="/participate" className="hoverLink">
              <span style={{ width: 237, color: "#FFFFFF" }}>
                <CompoundButton
                  secondaryText={"Stories, photos, artefacts"}
                  style={{
                    background: "#000000",
                    color: "#FFFFFF",
                    width: 281
                  }}
                  className="hoverLink"
                >
                  How to Partcipate
                </CompoundButton>
              </span>
            </Link>
          </div>
        </div>
      </section>

      <section className="row sectionBgBlack">
        <div className="col-12">
          <h4 className="titleMobWhite mt-5 mb-4">
            Frequently Asked
            <br /> Questions
          </h4>
          <div className="my-4">
            <img src={ImageFive} alt="tell your story" className="tellImg" />
          </div>
          <p className="tellStoryTxtWhite">
            Visit our FAQ section for fast answers to questions most users might
            have <br />
            using the memorabilia.
          </p>
          <div className="my-5 pb-3">
            <Link to="/faq">
              <CompoundButton
                secondaryText={`Frequently Asked Questions`}
                style={{ color: "#000000", width: 281 }}
              >
                FAQ
              </CompoundButton>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MHome;
