import React from 'react';
import Desktop from './desktop/research';
import Tablet from './tablet/research';
import Mobile from './mobile/research';
import { MOBILE, TABLET } from '../../resolution';

export default (props) => {
    if (props.resolution === MOBILE) {
        return <Mobile props={props} />
    } else if (props.resolution === TABLET) {
        return <Tablet props={props} />
    } else {
        return <Desktop props={props} />
    }
}
