import React, { useState } from "react";
import {
  Row,
  Col,
  Navbar,
  Nav,
  NavDropdown,
  Container,
  Form,
  FormControl,
  Button
} from "react-bootstrap";
import Logo from "../../../assests/image/logo1.webp";
import { Link } from "react-router-dom";

const MHeader = () => {
  let [drop, setDrop] = useState(false);
  const Toggler = () => {
    setDrop(!drop);
  };

  return (
    <div style={{ borderBottom: "1px solid  #E5E5E5" }}>
      <Navbar
        collapseOnSelect
        expand="lg"
        color="#141517"
        className={"nav__bar__font"}
      >
        <Navbar.Brand href="#home" style={{ marginLeft: '1rem'}}>
          <Link to="/">
            {drop === false ? (
              <img
                alt="logo"
                src={Logo}
                // className={"navbar__logo"}
                style={{ marginTop: "11vh" }}
                width="88.75"
                height="80.9"
              />
            ) : null}
          </Link>
        </Navbar.Brand>

        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          className="toggler"
          onClick={Toggler}
          style={{marginLeft: '90%'}}
        />
        <Navbar.Collapse id="responsive-navbar-nav">
        <Nav>
            <Nav.Link href="/searchperson">Person Search</Nav.Link>
            <Nav.Link href="/stories">Stories</Nav.Link>
            <Nav.Link href="/participate">How to Participate</Nav.Link>
            <Nav.Link href="/faq">FAQ</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    </div>
  );
};

export default MHeader;
