import React from "react";
import DesktopSingleStories from "./desktop/dSingleStory";
import MobileSingleStories from "./mobile/mSingleStory";
import { MOBILE, TABLET } from "../../resolution";

export default props => {
  if (props.resolution === MOBILE) {
    return <MobileSingleStories props={props} />;
  } else if (props.resolution === TABLET) {
    return <DesktopSingleStories props={props} />;
  } else {
    return <DesktopSingleStories props={props} />;
  }
};
