import React, { useState, useEffect } from "react";
import Full from "../../assests/image/fullStar.png";
import Half from "../../assests/image/halfStar.png";
const AuthorRating = () => {
  let [full, setFull] = useState([]);
  let [half, setHalf] = useState([]);

  let fullS = [];
  const HalfStarRender = () => {
    setHalf([1]);
  };

  const FullStarRender = () => {
    setFull([1, 2, 3, 4]);
  };

  useEffect(() => {
    FullStarRender();
    HalfStarRender();
  }, []);
  return (
    <span>
      <span>
        {full.map((h, index) => {
          return <img src={Full} alt="rating" key={'full' + index} />;
        })}
      </span>
      <span>
        {half.map((h, index) => {
          return <img src={Half} alt="rating" key={'half' + index}/>;
        })}
      </span>
    </span>
  );
};

export default AuthorRating;
