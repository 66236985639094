import React, { useEffect, useState } from "react";
import Insta from "../../../assests/image/instaLogo.webp";
import Facebook from "../../../assests/image/facebookLogo.webp";
import Twitter from "../../../assests/image/twitterLogo.png";
import LinkedIn from "../../../assests/image/linkedinLogo.png";
import Pinterest from "../../../assests/image/pinterestLogo.png";
import { GetStoryApi } from "../../../utils/apis";
import { Carousel } from "react-bootstrap";
import Loader from "../../../assests/image/loading.gif";
import StoryImg from "../components/storyImg";
import NoImages from "../../../assests/image/no-imageCollect.webp";
import "./mSingleStory.css";
const MSingleStory = ({ props }) => {
  const [story, setStory] = useState({});
  const [image, setImg] = useState([]);
  const [activeImg, setActiveImg] = useState(null);
  const [error, setError] = useState("");

  let storyId = props.match.params.id;
  const GetStory = Id => {
    return GetStoryApi(storyId)
      .then(result => {
        setStory(result.data.story.story);
        setImg(result.data.story.picture_url);
        setActiveImg(
          result.data.story.picture_url.length === 0
            ? undefined
            : result.data.story.picture_url[0].picture_url
        );
      })
      .catch(err => {
        setError(err);
      });
  };

  const SetImage = url => {
    setActiveImg(url);
  };
  // setTimeout(() => {
  //   setEnable(true);
  // }, 2000);
  useEffect(() => {
    GetStory();
  }, []);

  const AppendContent = () => {
    let container = document.getElementById("content");
    if (container) {
      container.innerHTML =
        story.content === undefined || story.content === null
          ? ` <img src=${Loader} alt="loader" />`
          : story.content;
    }
  };

  AppendContent();

  return (
    <div>
      <div className="row sectionBgWhite">
        <div className="col-12 topSpace">
          <h2 className="mobileTitle mb-4">{story.title}</h2>
        </div>
      </div>

      <section>
        <div className="row sectionBgWhite mt-3 mb-5">
          <div className="col-12">
            <StoryImg image={activeImg} styleClass={"SSImg"} />
          </div>

          <div className="col-12">
            <div className="conflictHead mt-4">Conflict</div>
            <div className="consMobile">Objects, Artefacts</div>
            <div className="mainStory mt-4" id="content"></div>
          </div>
        </div>
      </section>

      <section>
        <div className="row sectionBgWhite mb-4">
          <div className="col-12">
            <div className="tagsMobile">Tags</div>
            <div className="tags">
              Bombs, Genocide, Mass death, improvisation
            </div>
          </div>
        </div>

        <div className="row sectionBgWhite mb-4">
          <div className="col-12">
            <div className="tagsMobile">Author</div>
            <div className="tags">{story.author}</div>
          </div>
        </div>

        <div className="row sectionBgWhite mb-4">
          <div className="col-12">
            <div className="tagsMobile">Share</div>
            <div className="tags">
              <span className="socialSpace">
                <img
                  src={Insta}
                  alt="Link to instagram"
                  width="34"
                  height="34"
                />
              </span>
              <span className="socialSpace">
                <img
                  src={Facebook}
                  alt="Link to facebook"
                  width="34"
                  height="34"
                />
              </span>
              <span className="socialSpace">
                <img
                  src={Twitter}
                  alt="Link to twitter"
                  width="34"
                  height="34"
                />
              </span>
              <span className="socialSpace">
                <img
                  src={LinkedIn}
                  alt="Link to LinkedIn"
                  width="34"
                  height="34"
                />
              </span>
              <span className="socialSpace">
                <img
                  src={Pinterest}
                  alt="Link to pinterest"
                  width="34"
                  height="34"
                />
              </span>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="row sectionBgWhite mb-5">
          <div className="col-12">
            <p className="otherTxt">Other images</p>
            <div className="darkIcon">
              <Carousel
                nextIcon={
                  <span className="fa fa-chevron-right darkIcon"></span>
                }
                prevIcon={<span className="fa fa-chevron-left darkIcon"></span>}
              >
                {image.length > 0 ? (
                  image.map((i, x) => {
                    return (
                      <Carousel.Item>
                        <span className="py-4 my-3 d-flex justify-content-center">
                          <div
                            className="oPhoto"
                            onClick={() => setActiveImg(i.picture_url)}
                          >
                            <img
                              src={i.picture_url}
                              alt="other images in story gallery"
                              className="oPhoto"
                            />
                          </div>
                        </span>
                      </Carousel.Item>
                    );
                  })
                ) : (
                  <div className="d-flex justify-content-center">
                    <img src={NoImages} alt="no others" />
                  </div>
                )}
              </Carousel>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default MSingleStory;
