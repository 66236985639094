import React from "react";
import "./mFaq.css";
import QA from "../quetionsAndAnswers";
import { Accordion, Card } from "react-bootstrap";
const MFaq = () => {
  return (
    <div>
      <div className="row sectionBgBlack">
        <div className="col-12 topSpace">
          <h2 className="titleMobWhite mt-5 pb-4">FAQ</h2>
        </div>
      </div>

      <section className="faqQA sectionBgBlack pb-5">
        {QA.map((qa, index) => {
          return (
            <Accordion defaultActiveKey="0" key={"QA" + index}>
              <Card>
                <Accordion.Toggle as={Card.Header} eventKey={0}>
                  <div>
                    {" "}
                    <h2>{qa.question}</h2>{" "}
                    <span>
                      <i className="fas fa-chevron-down"></i>
                    </span>{" "}
                  </div>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={0}>
                  <Card.Body>{qa.answer}</Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          );
        })}
      </section>
    </div>
  );
};

export default MFaq;
