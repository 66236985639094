import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { DefaultButton } from "office-ui-fabric-react";
import * as serviceWorker from "./serviceWorker";
import {isMobile} from 'react-device-detect';
// window.jQuery = window.$ = require('jquery');
// window.$ = window.JQuery = Jquery;
console.log("jquery check in windows", window.$);


let userDeviceTypeIsMobile = window.screen.width < 768;
/*
if (userDeviceTypeIsMobile) {
  const divStyled = {
    height: window.innerHeight,
    width: "100%",
    boxSizing: "border-box"
  };

  ReactDOM.render(
    <div style={divStyled}>
      <AppMobile />
    </div>,
    document.getElementById("root")
  );
} else ReactDOM.render(<App />, document.getElementById("root"));

function renderDesktopView() {
  document.querySelector("meta[name=viewport]").content = "";
  ReactDOM.render(<App />, document.getElementById("root"));
}
*/



ReactDOM.render(<App />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
