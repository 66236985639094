import React, { useState } from "react";
import "./mDigitalGuideline.css";
import Answers from "../components/answers";
import { Row, Container, Col } from "react-bootstrap";
import { first, second } from "../components/questionsAndAnswers";
import withDigitalUtils from "../digitalGuidelineUtils";
import { Accordion, Card, Button } from "react-bootstrap";

const MDigitalGuideline = props => {
  return (
    <div className="digitalBg">
      <div className="mx-3 pt-5">
        <div className="row" style={{ margin: 0 }}>
          <div className="col-md-9 pt-5 noPadding">
            <div className="guideline">Digitization Guidelines</div>
          </div>
        </div>
        <div className="row" style={{ margin: 0 }}>
          <div className="col-md-12 mt-5 digital-text-1 noPadding">
            We welcome submission of digital records. These tips will help you
            create digital copies of your photos and documents which will shine
            in the NBWM.
          </div>
        </div>

      </div>

        {/* New Accordion Starts */}
        <section className='mt-5 pb-5'>
          <Accordion defaultActiveKey="0">
            {[...first, ...second].map((x, index) => {
              return (
                <Card key={"pri" + index}>
                  <Accordion.Toggle as={Card.Header} id="arcdigital" eventKey={index} style={{paddingLeft:'1rem'}}>
                    <div>
                      <h2 style={{width : '95%'}}>{x.question}</h2>
                      <span>
                        <i className="fas fa-chevron-down"></i>
                      </span>
                    </div>
                  </Accordion.Toggle>
                  <Accordion.Collapse eventKey={index}>
                    <Card.Body id="digital_content">{x.answer}</Card.Body>
                  </Accordion.Collapse>
                </Card>
              );
            })}
          </Accordion>
        </section>
        {/* New Accordion Ends */}
    </div>
  );
};

export default withDigitalUtils(MDigitalGuideline);
