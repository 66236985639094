import React from 'react'
import { Row, Col, Container, Table } from 'react-bootstrap'
import research0 from '../../../assests/image/researchImg.webp'
import Btn from '../../../components/mainBtn/mainBtn'
import { Link } from 'react-router-dom';
import './research.css'
const Research = () => {
    return (
        <div>
            <Row style={{ margin: 0 }}>
                <div className="body__wrapper">
                    <Row>
                        <Col md={12} style={{ paddingTop: "10%" }}>
                            <div style={{ width: "80%" }}>
                                <h3 style={{ fontSize: "40px", fontFamily: "Segoe UI" }} className="pb-5">Getting started with the Biafra War Memorial Museum</h3>

                                <p style={{ fontSize: "24.7px", fontFamily: "Poppins", lineHeight: "40px" }}> You can search the Biafra War Museum for biographical and service details for more than 140,000 witnesses & survivors from the war, both alive and dead.</p>

                                <p className="pt-4 pb-5" style={{ fontSize: "15px", fontFamily: "Segoe UI", lineHeight: "24px" }}>
                                    The biographies in the Biafra War Museum have been built up over 10 years, from official military records, publications including newspapers and information provided by family members. The records are works in progress. Our aim is for the people of Nigeria to continue to add both official and personal details to records to pay tribute to those who have served this country in local and international conflicts.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </div>
            </Row>

            <Row style={{ margin: 0, color: "white", background: "black" }}>
                <div className="body__wrapper">
                    <Row>
                        <Col md={6} style={{ display: "flex", alignItems: "center" }}>
                            <div>
                                <h3 style={{ fontSize: "40px", fontFamily: "Segoe UI" }}>Get help with searching</h3>
                                <p style={{ fontFamily: "Segoe UI", fontSize: "15px", lineHeight: "24px" }}>
                                    Begin your search with the <Link>person</Link> search by typing in the family name, first name and war or conflict of the person you are looking for. If you don't find your person, we suggest you use the first letter only of the first name, as official records have names entered in a variety of ways.
                        </p>
                            </div>
                        </Col>
                        <Col md={6} className="d-flex  align-items-center " style={{ justifyContent: "flex-end" }}>
                            <div>
                                <img src={research0} alt="research0" width="100%" height="300" />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Row>

        <Row style={{ margin: 0 }}>
            <div className="body__wrapper">
                <Row>
                    <Col md={6} className="d-flex  align-items-center  ">
                        <div>
                            <img src={research0} alt="research0" width="100%" height="330" />
                        </div>
                    </Col>
                    <Col md={6} style={{ paddingTop: "10%" }}>
                        <div >
                            <h3 style={{ fontSize: "40px", fontFamily: "Segoe UI" }}>Can't find who you're looking for?</h3>
                            <p style={{ fontFamily: "Segoe UI", fontSize: "15px", lineHeight: "24px" }}>If you can't find the person you're looking for, it may be that a record has not been created for this individual yet. Learn how you can contribute to the museum record to enrich this resource for everyone.</p>
                        </div>
                        <div className="my-4">
                            <Btn color="white" bgColor="black" name="Contribute" text="Choose how to contribute to the museum" />
                        </div>
                    </Col>
                </Row>
            </div>
            </Row >


    <Row style={{ margin: 0, color: "white", background: "black", paddingTop: "6%" }}>
        <div className="body__wrapper">
            <Row>
                <Col md={4}>
                    <div>
                        <h3 style={{ fontSize: 40, lineHeight: "53px", fontFamily: "Segoe UI" }}>Get help with referencing sources</h3>
                        <p style={{ fontSize: 15, lineHeight: "24px", fontFamily: "Segoe UI" }}>BWMM uses the APA citation style when referencing sources of record pages.<br />
                            <br />
                            We've put together a set of examples of the APA citation styles to help you reference your
                                    </p>
                    </div>
                </Col>
                <Col md={8} style={{ marginBottom: "14vh" }}>
                    <table>
                        <tr>
                            <th className={"table__header"}>Format</th>
                            <th className={"table__header"}>Style</th>
                            <th className={"table__header"}>Example</th>
                        </tr>

                        <tr>
                            <td style={{ color: "#9B9B9B" }}>Book</td>
                            <td style={{ width: "20%" }}>Author. (Year of publication). Title of book. Place of publication: Publisher.</td>
                            <td>Chukwdi, E. (1960). 25 Battalion. Wellington: Department of Internal Affairs, War History Branch.</td>
                        </tr>
                        <tr>
                            <td style={{ color: "#9B9B9B", fontSize: "24.7219px", fontFamily: "Segoe UI" }}>Journal article</td>
                            <td style={{ width: "20%" }}>Author. (Year of publication). Title of book. Place of publication: Publisher.</td>
                            <td>Chukwdi, E. (1960). 25 Battalion. Wellington: Department of Internal Affairs, War History Branch.</td>
                        </tr>
                        <tr>
                            <td style={{ color: "#9B9B9B" }}>Newspaper article (no author)</td>
                            <td style={{ width: "20%" }}>Author. (Year of publication). Title of book. Place of publication: Publisher.</td>
                            <td>Chukwdi, E. (1960). 25 Battalion. Wellington: Department of Internal Affairs, War History Branch.</td>
                        </tr>
                        <tr>
                            <td style={{ color: "#9B9B9B" }}>Newspaper article (no author)</td>
                            <td style={{ width: "20%" }}>Author. (Year of publication). Title of book. Place of publication: Publisher.</td>
                            <td>Chukwdi, E. (1960). 25 Battalion. Wellington: Department of Internal Affairs, War History Branch.</td>
                        </tr>
                        <tr>
                            <td style={{ color: "#9B9B9B", fontSize: "24.7219px", fontFamily: "Segoe UI" }}>Newspaper article (no author)</td>
                            <td style={{ width: "20%" }}>Author. (Year of publication). Title of book. Place of publication: Publisher.</td>
                            <td>Chukwdi, E. (1960). 25 Battalion. Wellington: Department of Internal Affairs, War History Branch.</td>
                        </tr>
                        <tr>
                            <td style={{ color: "#9B9B9B" }}>Newspaper article (no author)</td>
                            <td style={{ width: "20%" }}>Author. (Year of publication). Title of book. Place of publication: Publisher.</td>
                            <td>Chukwdi, E. (1960). 25 Battalion. Wellington: Department of Internal Affairs, War History Branch.</td>
                        </tr>
                    </table>
                </Col>
            </Row>
        </div>
    </Row>

    <Row style={{ padding: "0", margin: "0", marginBottom: "21vh" }}>
        <div className="body__wrapper">
            <Row>
                <Col md={4} style={{ padding: "10px", marginTop: "15.3vh" }}>
                    <h3 className={"participate__header"}>Terms of Use</h3>
                    <p style={{ fontSize: "15px", fontFamily: "Segoe UI" }}>
                        Thanks for helping us enhance the Nigeria - Biafra War Database Online Memorial. You can find the terms covering the
                        contribution of content in our legal section.
                            </p>
                    <p>
                        Please take a moment to read them before you upload any materials to this website.
                            </p>
                </Col>
                <Col md={8}>
                    <Row>
                        <Col md={12} style={{ paddingLeft: "10%", marginTop: "19.3vh" }}>
                            <h2 className={"participate__header"} style={{ marginBottom: "8vh", fontFamily: "Segoe UI" }}>Moderation Guidelines</h2>
                            <p style={{ fontSize: "24px", lineHeight: "40px", letterSpacing: "0.01em", fontFamily: "Poppins" }}>
                                All user-generated content is automatically published. This content is passed through a spam filter
                                before publishing. If you find that your comment doesn't immediately show up, it may have been flagged as
                                spam. Please contact the support team if this happens. User-generated content is clearly marked as a Public
                                Source. It is not actively moderated.
                                    </p>
                        </Col>
                        <Col md={12} style={{ paddingLeft: "10%", marginTop: "19.3vh" }}>
                            <h2 className={"participate__header"} style={{ marginBottom: "8vh" }}>Cookies</h2>
                            <p style={{ fontSize: "24px", lineHeight: "40px", letterSpacing: "0.01em", fontFamily: "Poppins" }}>
                               The Nigeria -  Biafra Database War Museum (BDWM) users should be aware that certain words, terms or descriptions may
                                be culturally sensitive and may be considered inappropriate today, but may have reflected the author’s /
                                creator’s attitude or that of the period in which they were written.
                                    </p>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    </Row>

    <Row style={{ margin: 0, color: "white", background: "black" }}>
        <div className="body__wrapper">
            <Row>
                <Col style={{ padding: "21vh 0" }}>
                    <h5 className={"participate__header"} style={{ marginBottom: "8vh" }}>Privacy Statement</h5>
                    <p className="contentFont">
                        BDWM collects information and data from publicly available official sources and contributions from third parties in order to
                        create a national biographical database recording and preserving the memories and honoring the lives of Nigerians who served or were affected by the war.
                        </p>
                    <p className="contentFont">
                        We collect data on the service experiences of people still living or dead. Participating and contributing data to BDWM is entirely voluntary.
                        Individuals featured on have the right to ask for a printed or digital copy of any information we hold about them. They may also ask to have it removed or
                        corrected if wrong. We will make every effort to not collect or display information on living people submitted by third parties unless they have agreed.
                        </p>
                    <p className="contentFont">
                        If you would like a copy of your information or have it removed or corrected, please contact us +234 11 22 33 00 or send us an email on <Link>ozoemena@bdwm.com.ng </Link>
                    </p>
                </Col>
            </Row>
        </div>
    </Row>
        </div >
    )
}

export default Research