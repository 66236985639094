import React, { useState, useEffect } from "react";
import { Row, Container, Col } from "react-bootstrap";
import "./faq.css";
import { Link } from "react-router-dom";
import QANDA from "../quetionsAndAnswers";
import Answers from "../components/Answers";
var $ = window.$;

const Faq = props => {
  var [showHide, setShowHide] = useState({ visibleResponses: {} });

  const toggle = index => {
    const exists = showHide.visibleResponses[index];

    if (exists) {
      delete showHide.visibleResponses[index];
    } else {
      showHide.visibleResponses[index] = true;
    }

    setShowHide({ visibleResponses: showHide.visibleResponses });
  };

  useEffect(() => { }, [showHide.visibleResponses]);

  return (
    <div>
      <Row
        style={{
          padding: "0",
          margin: "0",
          color: "white",
          background: "black"
        }}
      >
        <div className="body__wrapper">
          <div className="row">
            <Col md={3} style={{ marginTop: "11.3vh" }}>
              <h3 className={"participate__header"} style={{ fontSize: 25 }}>FAQ</h3>
            </Col>
            <div className="">
              <div className="row">
                {/* <Col
                  style={{ padding: "0 5%", marginTop: "8vh", marginBottom: '8vh' }}
                >
                  <h2
                    className={"participate__header"}
                    style={{ marginBottom: "2vh", fontSize: 25, lineHeight: 'normal' }}
                  >
                    What is the Nigeria - Biafra War Memories?
                  </h2>
                  <p
                    style={{
                      fontsize: "24px",
                      lineHeight: "40px",
                      letterSpacing: "0.01em"
                    }}
                  >
                    Find out more about the project on the{" "}
                    <Link to="/about">About the project</Link>
                  </p>
                </Col> */}
                {QANDA.map((qst, index) => {
                  return (
                    <Col
                      md={12}
                      style={{
                        padding: "0 5%",
                        marginBottom: "7vh"
                      }}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className="participate__header"
                          >
                            <h2 className={"participate__header"}
                              style={{ marginBottom: "2vh", fontSize: 25, lineHeight: 'normal' }}
                            >
                              {qst.question}
                            </h2>
                          </div>
                        </div>
                      </div>

                      {!showHide.visibleResponses[index] && (
                        <Answers ans={qst.answer} index={index} key={index} />
                      )}
                    </Col>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default Faq;
