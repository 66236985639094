import React from "react";
import { Row, Col } from "react-bootstrap";
import logo from "../../../assests/image/logo.webp";
import "./footer.css";

const Footer = () => {
  return (
    <div style={{ background: "#F2F2F2" }}>
      <div className='mx-4' style={{ margin: "auto" }}>
        <Row>
          <Col md={12} style={{ padding: "9.7vh 0px 0px 3.5%" }}>
            <div style={{ marginBottom: "1vh" }}>
              <img src={logo} alt="logo" width="25%" />
            </div>
          </Col>
        </Row>
        <Row style={{ margin: "8vh 0 0 0", display: 'flex', justifyContent: 'space-between', maxWidth: '100%' }}
          className={'container'}
        >
          <div className={'w-50 pr-5'}>
            {/* <p className=""> */}
            <div className={"footer__text__font pt-4"}>
              The Nigeria - Biafra War Memories is a repository of stories,
              interviews and memorabilia sourced from a community of survivors.
            </div>
            {/* </p> */}
          </div>
          <div className={'w-50'}>
            <div>
              <div style={{ fontWeight: "bold", marginBottom: "2.7vh" }}>
                <small className={"footer__header"}>CONTACTS</small>
              </div>
              <div className="pt-2">
                <address>
                  <div>
                    <small className={"footer__text__font"}>
                      No. 2 Agwu Street, off Umuoji
                      <br /> Street, Independence Layout
                      <br /> Enugu-Enugu, Nigeria
                    </small>
                  </div>
                  <div>
                    <small
                      style={{ margin: 0, padding: 0 }}
                      className={"footer__text__font"}
                    >
                      +234 000 111 2222
                    </small>
                  </div>
                  <div>
                    <small
                      style={{
                        color: "#9B9B9B",
                        margin: 0,
                        padding: 0,
                        textDecoration: "underline"
                      }}
                      className={"footer__text__font"}
                    >
                      {/* info@bwmm.com */}
                      <a href="mailto:kedu@centreformemories.org">
                        kedu@centreformemories.org
                      </a>
                      <br />
                    </small>
                  </div>
                  <div>
                    <small
                      style={{
                        color: "#9B9B9B",
                        margin: 0,
                        padding: 0,
                        textDecoration: "underline"
                      }}
                      className={"footer__text__font"}
                    >
                      <a href="http://centreformemories.org">
                        http://centreformemories.org
                      </a>
                      <br />
                      {/* http://centreformemories.com */}
                    </small>
                  </div>
                </address>
              </div>
            </div>
          </div>
          <div className={'w-50'}>
            <div className="my-5" >
              <div style={{ fontWeight: "bold", marginBottom: "2.7vh" }}>
                <small className={"footer__header"}>CENTRE FOR MEMORIES</small>
              </div>
              <div className="pt-2">
                <small className={"footer__text__font"}>
                  The Centre For Memories (CFM) is the leading hub for Igbo
                  history, culture, and excellence.
                </small>
              </div>

              {/* <div
                className="input-group"
                style={{ width: "80%", marginTop: "4.72vh" }}
              >
                <input
                  type="text"
                  className={"form-control search__input"}
                  placeholder="Email"
                />
                <div className="input-group-append">
                  <button type="button" className="btn sub__btn">
                    OK
                  </button>
                </div>
              </div> */}
            </div>
          </div>
        </Row>
        <Row style={{ margin: 0 }}>
          <Col md={12} className="pb-3">
            <div style={{ marginTop: "12.33px" }}>
              <small style={{ color: "#9B9B9B" }}>
                © {new Date().getFullYear()} Nigeria - Biafra War Memories
              </small>
            </div>
          </Col>
        </Row>
      </div >
    </div >
  );
};

export default Footer;
