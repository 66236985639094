import React, { useState, useEffect } from "react";
import Insta from "../../../assests/image/instaLogo.webp";
import Facebook from "../../../assests/image/facebookLogo.webp";
import Twitter from "../../../assests/image/twitterLogo.png";
import LinkedIn from "../../../assests/image/linkedinLogo.png";
import Pinterest from "../../../assests/image/pinterestLogo.png";
import Loader from "../../../assests/image/loading.gif";
import "../stories.css";
import { GetStoryApi } from "../../../utils/apis";
import NoImage from "../../../assests/image/no-image.webp";
import NoImages from "../../../assests/image/no-imageCollect.webp";
import StoryImg from "../components/storyImg";
import AlertCard from "../../../components/alertCard/alertCard";
const SingleStory = ({ props }) => {
  const [story, setStory] = useState({});
  const [image, setImg] = useState([]);
  const [activeImg, setActiveImg] = useState(null);
  const [error, setError] = useState("");

  let storyId = props.match.params.id;
  const GetStory = Id => {
    return GetStoryApi(storyId)
      .then(result => {
        setStory(result.data.story.story);
        setImg(result.data.story.picture_url);
        setActiveImg(
          result.data.story.picture_url.length === 0
            ? undefined
            : result.data.story.picture_url[0].picture_url
        );
      })
      .catch(err => {
        setError(err);
      });
  };

  const SetImage = url => {
    setActiveImg(url);
  };
  // setTimeout(() => {
  //   setEnable(true);
  // }, 2000);
  useEffect(() => {
    GetStory();
  }, []);

  const AppendContent = () => {
    let container = document.getElementById("content");
    if (container) {
      container.innerHTML =
        story.content === undefined || story.content === null
          ? ` <img src=${Loader} alt="loader" />`
          : story.content;
    }
  };

  AppendContent();
  return (
    <div>
      <div style={{ marginTop: "8%", paddingLeft: "3.4rem" }}>
        {/* <AlertCard message={error} error={true} /> */}
        <div className="row" style={{ margin: 0 }}>
          <div className="col-md-6">
            <div className="addTitle">{story.title}</div>

            <div style={{ marginTop: "3.3rem" }}>
              <p className="conText">Conflict</p>
              <p className="subConText">Objects, Artefacts</p>

              {/* <p className="storyText pt-3" id="content"></p> */}
              <div className="row">
                <div className="col-md-8 col-sm-12">
                  <div className="storyText pt-3" id="content"></div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6">
            <StoryImg image={activeImg} />
          </div>
        </div>

        <div className="row " style={{ margin: 0, paddingTop: "2.5rem" }}>
          <div className="col-md-3">
            <div>
              <p className="conText">Tags</p>
              <p className="subConText" style={{ width: "80%" }}>
                Bombs, Genocide, Mass death, improvisation
              </p>
            </div>
          </div>
          <div className="col-md-3">
            <div>
              <p className="conText">Author</p>
              <p className="subConText">{story.author}</p>
            </div>
          </div>
          <div className="col-md-6">
            <div>
              <p className="conText">Share</p>
              <p>
                <span>
                  <img src={Insta} alt="Link to instagram" />
                </span>
                <span style={{ marginLeft: "2px" }}>
                  <img src={Facebook} alt="Link to facebook" />
                </span>
                <span style={{ marginLeft: "2px" }}>
                  <img src={Twitter} alt="Link to twitter" />
                </span>
                <span style={{ marginLeft: "2px" }}>
                  <img src={LinkedIn} alt="Link to LinkedIn" />
                </span>
                <span style={{ marginLeft: "2px" }}>
                  <img src={Pinterest} alt="Link to pinterest" />
                </span>
              </p>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, paddingTop: "2.5rem" }}>
          <div className="col-12" style={{ marginBottom: "4rem" }}>
            <p className="otherTitle">Other images</p>
            <div className="my-4">
              {image.length > 0 ? (
                image.map((i, x) => {
                  return (
                    <span className="mr-3 py-4 my-3">
                      <div
                        className="otherImg"
                        onClick={() => setActiveImg(i.picture_url)}
                      >
                        <img
                          src={i.picture_url}
                          alt="other images in story gallery"
                          className="otherImg"
                        />
                      </div>
                    </span>
                  );
                })
              ) : (
                <img src={NoImages} alt="no others" />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SingleStory;
