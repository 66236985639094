import React from "react";
import "./mobileResultCard.css";
// import b from "../../assests/image/b.png";
import Flower from "../../assests/image/tributeFlower.png";
import { Link, withRouter } from "react-router-dom";
const MobileResultCard = props => {
  const OpenPerson = () => {
    props.history.push({ pathname: `/profile/${props.id}` });
  };
  return (
    <>
      <div
        className="row mobileResultCard"
        style={{ margin: 0 }}
        onClick={OpenPerson}
      >
        <div className="col-xs-4 imgAdjust">
          <div className="imageResolution">
            <img src={props.pic} alt="result" />
          </div>
          {/* <div className="report mt-1">Report this photo</div> */}
        </div>
        <div className="col-xs-2 space"></div>
        <div className="col-xs-8 contentP">
          <div className="row" style={{ margin: 0 }}>
            <div className="col-xs-8 noPadding text-1">Military</div>
          </div>
          <div className="row" style={{ margin: 0 }}>
            <div
              className="col-xs-8 noPadding text-2"
              style={{ textTransform: "capitalize" }}
            >
              {props.name}
            </div>
          </div>
          <div className="row" style={{ margin: 0 }}>
            <div className="col-xs-6 col-six">
              <i className="fa fa-link fa-col"></i>&nbsp;
              <span className="text-3">Starvation Galore</span>
            </div>
            <div className="col-xs-6 col-six">
              <i className="fa fa-link fa-col"></i>&nbsp;
              <span className="text-3">I Buried My Son</span>
            </div>
          </div>
          <div className="row" style={{ margin: 0 }}>
            <div className="col-xs-12">
              <div className="text-4">Attachments: 12</div>
            </div>
          </div>
          <div className="row" style={{ margin: 0 }}>
            <div className="col-xs-9" style={{ width: "65%" }}>
              <div className="text-5">Status: Alive</div>
            </div>
            <div className="col-xs-3" style={{ width: "35%" }}>
              <div>
                <img src={Flower} />
                <span className="flower">40k</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default withRouter(MobileResultCard);
