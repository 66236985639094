import React from "react";
import "./personSearch.css";
import {
  Row,
  Col,
} from "react-bootstrap";

import { Link } from "react-router-dom";
import { CompoundButton, TextField } from "office-ui-fabric-react";
import { Stack, SearchBox, Checkbox } from "office-ui-fabric-react";
import ResultCard from '../../../components/resultCard/resultCard';
import Loader from "../../../assests/image/loading.gif";
import withPersonUtils from '../personSearchUtils';
var { width, height } = window.screen;


const SearchPerson = (props) => {
  return (
    <div className="mx-2" style={{ margin: "10.0vh" }}>
      <div className="my-5 mx-4" >
        <Row>
          <Col style={{ marginBottom: "12vh" }}>
            <h2
              style={{
                marginBottom: "8vh",
                fontWeight: "bold",
                fontSize: "40px",
                fontFamily: "Segoe UI"
              }}
            >
              Person Search
            </h2>
            <Row>
              <Col md={12}>
                <Stack horizontal={false} tokens={{ childrenGap: 10 }}>
                  <TextField
                    iconProps={{
                      iconName: 'ChevronRight',
                      styles: {
                        root: {
                          backgroundColor: '#000',
                          height: '100%',
                          position: 'initial',
                          width: '100px',
                          color: '#fff',
                          justifyContent : 'center',
                          alignItems : 'center',
                          display : 'flex'
                        }
                      }
                    }}
                    placeholder="Search for person"
                    onChange={props.handleSearch}
                    styles={{ fieldGroup: { height: "7vh" }, iconContainer: { height: '100%', fontWeight: 'bold', fontSize: 15 } }}
                  />

                  <Checkbox label="Images only" />
                </Stack>
              </Col>
              {/* <Col md={4}>
                <Drop
                  styles={{
                    dropdown: { width: "auto" },                    
                  }}
                  placeHolder="Select a conflict areas"
                  options={conflictTypes}
                />
              </Col> */}

            </Row>
          </Col>
        </Row>

        {
          /**If not searching at all */
          !props.searching.ongoing ?
            /* Add Person Starts */
            <div>
              <Row>
                <Col>
                  <Link to="/addperson">
                    <CompoundButton
                      secondaryText="Add a person to the database"
                      style={{
                        background: "#000000",
                        color: "#FFFFFF",
                        width: 281
                      }}
                      className="hoverLink"
                    >
                      Add person
                        </CompoundButton>
                  </Link>
                </Col>
              </Row>
              <Row>

                <div className="col-md-8 col-xs-12 col-sm-12 my-5">
                  <p className="search_comment_text" >
                    Looking for a loved one involved in the war? Search our records,
                        <br /> to find them or add them to our records by using the Add{" "}
                    <br /> Person button above.
                      </p>
                </div>
              </Row>
            </div>

            /* Add Person Ends */
            /**If searching is ongoing and no result yet */
            :
            (props.searching.ongoing && props.searching.fetching) ?
              <div className="d-flex justify-content-center">
                <img src={Loader} alt="loading" width="200" height="200" />
              </div> :
              /**If searching is ongoing and result is gotten */
              (props.searching.ongoing && !props.searching.fetching && props.persons.count > 0) ?

                props.persons.result.map((p, index) => {
                  return (
                    <Row>
                      <Col md={12}>
                        <div className="cardRes my-3">
                          <ResultCard
                            name={p.archive.archive_name}
                            pic={p.archive.archive_picture_url}
                            id={p.archive.id}
                          />
                        </div>
                      </Col>
                    </Row>

                  );
                }) :

                /* No Result Search Starts */
                <div className="col-md-12 col-xs-12 col-sm-12">
                  <div className='row mt-5 nopadding' style={{ margin: 0 }}>
                    <div className='col-md-5 col-sm-5 col-xs-5 noPadding'>
                      <div className='search_comment_text_header'>
                        Can’t find who you’re looking for ?
                        </div>
                    </div>
                    <div className="col-md-7"></div>
                    <div className='col-md-6 pr-4 mt-3 search_cant_find noPadding'>
                      If you can't find the person you're looking for, it may be that a record has not been created for this individual yet. Learn how you can contribute to the museum record to enrich this resource for everyone.
                    </div>
                  </div>
                  <div className='col-md-12 my-5' style={{ padding: 0 }}>
                    <CompoundButton
                      secondaryText="Choose how to contribute to the museum"
                      style={{
                        background: "#000000",
                        color: "#FFFFFF",
                        width: 281
                      }}
                      className="hoverLink"
                    >
                      Contribute
                        </CompoundButton>
                  </div>
                </div>
          /* No Result Search Ends */

        }
        {/* Result Search Ends */}


      </div>
    </div>
  );
};

export default withPersonUtils(SearchPerson);
