import React from "react";
import Desktop from "./desktop/about";
import Tablet from "./tablet/about";
import Mobile from "./mobile/mAbout";
import { MOBILE, TABLET } from "../../resolution";

export default props => {
  if (props.resolution === MOBILE) {
    return <Mobile props={props} />;
  } else if (props.resolution === TABLET) {
    return <Tablet props={props} />;
  } else {
    return <Desktop props={props} />;
  }
};
