import axios from "axios";

let baseurl = {
  //live: "http://api-biafra.genesystechhub.com/api"
  live: "https://biafra.partzshop.com/api"
  // live: "http://localhost:8000/api"
};

let nodeBaseurl = {
  live: "https://biafra-api.herokuapp.com/api"
};

const AddPersonApi = (data = {}) => {
  return (
    axios
      .post(`${baseurl.live}/create_archive`, data, {
        headers: {
          "Content-Type":
            "application/x-www-form-urlencoded;charset=UTF-8;text/json;multipart/form-data"
        }
      })
      // .then(result => result.json())
      .then(result => {

      })
      .catch(err => {
        return err;
      })
  );
};
const AddStoryApi = data => {
  return (
    axios
      .post(`${baseurl.live}/create_story`, data, {
        headers: {
          "Content-Type":
            "application/x-www-form-urlencoded;charset=UTF-8;text/json;multipart/form-data"
        }
      })
      // .then(result => result.json())
      .then(result => {
        return result;
      })
      .catch(err => {
        return err;
      })
  );
};

/**Url example
 * https://biafra.partzshop.com/api/get_stories/10?page=1
 * */
const GetStoriesApi = (perPage = 10, pagenumber = 1) => {
  var url = `${baseurl.live}/get_stories/${perPage}?page=${pagenumber}`;
  return axios
    .get(url)
    .then(result => {
      return result;
    })
    .catch(err => {
      return err;
    });
};

const GetStoryApi = id => {
  return axios
    .get(`${baseurl.live}/get_story/${id}`)
    .then(result => {
      return result;
    })
    .catch(err => {
      return err;
    });
};

const GetPersonApi = id => {
  return axios
    .get(`${baseurl.live}/get_archive/${id}`)
    .then(result => {
      return result;
    })
    .catch(err => {
      return err;
    });
};

const GetGallery = () => {
  return axios
    .get(`${baseurl.live}/get_galleries`)
    .then(result => {
      return result;
    })
    .catch(err => {
      return err;
    });
};

const SearchPersonApi = data => {
  return axios
    .post(`${baseurl.live}/search_archive`, data, {
      headers: {
        "Content-Type":
          "application/x-www-form-urlencoded;charset=UTF-8;text/json;multipart/form-data"
      }
    })
    .then(res => {
      return res;
    })
    .catch(err => {
      return err;
    });
};

export {
  AddPersonApi,
  GetStoriesApi,
  GetStoryApi,
  AddStoryApi,
  GetGallery,
  GetPersonApi,
  SearchPersonApi
};
