import React, { useEffect } from "react";
import "./mAddStories.css";
import { TextField, Dropdown, Stack, DefaultButton } from "office-ui-fabric-react";
import "../dropify.css";
import "../bootstrap3-wysihtml5.min.css";
import "../bootstrapp-theme.min.css";
import ImgSymbol from "../../../assests/image/photowatermark.png";
import { Link } from "react-router-dom";
import withStoryApi from '../addStoriyUtils';
const MAddStories = (props) => {
  var { on, errMessage, picture, handleChange, handleImg, handleSubmit } = props;

  return (
    <div>
      <div className="row sectionBgWhite">
        <div className="col-12 topSpace">
          <h2 className="mobileTitle mb-4">
            Add stories from the
            <br /> war
          </h2>
          <p className="addStoriesHeader mt-4">
            Stories <span>(Add stories)</span>
          </p>
        </div>
      </div>

      <section className="row sectionBgWhite graySpace">
        <div className="col-12">
          <TextField
            label="Title of Story"
            placeholder="Add a title..."
            id="title"
            required
            onChange={handleChange}
          />
          <TextField
            label="Author"
            placeholder="Add author name"
            id="author"
            required
            onChange={handleChange}
          />

          <div className="mt-3">
            <h3 className="enterStoryHeader">Story</h3>
            <textarea
              rows="8"
              className="textBox wysihtml5-editor placeholder"
              id="txtexp"
              placeholder="Tell your story"
              style={{ color: "black" }}
              required
            ></textarea>
          </div>
        </div>
      </section>
      <div className="row sectionBgWhite">
        <div className="col-12">
          <p className="addStoriesHeader mt-4">
            Media <span>(Add images, video or audio)</span>
          </p>
        </div>
      </div>

      <section
        className="row sectionBgWhite graySpace"
        style={{ marginBottom: "1rem" }}
      >
        <div className="col-12">
          <div className="grayBox d-flex justify-content-center align-items-center">
            <div
              className="smallBox d-flex justify-content-center align-items-center"
              style={{ width: "100%" }}
            >
              <span className="fileInput">
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  className="inputfile"
                  id="image_url"
                  multiple
                />
                <img src={ImgSymbol} alt="tap to add" />
                <p className="tapText">
                  Tap to Upload
                  <br /> Image
                </p>
                {/* <div className={"seal"}>0 image(s)</div> */}
                <p className="tapText">
                  Tap to Upload
                    <br /> Image
                  </p>
                <div className={picture.length > 0 ? "sealGreen" : "seal"}>
                  {picture.length} image(s)
                  </div>
              </span>

              {/* <span className="fileInput mx-5">
                  <input
                    type="file"
                    accept=".m4a, .mp4, .f4v, .m4b, .m4r, f4b, .mov, .3gp, .wmv, .mpeg, .avi, .webm, .flv, .hdv"
                    className="inputfile"
                    id="video_url"
                    onChange={handleVideo}
                    multiple
                  />
                  <img src={VideoSymbol} alt="tap to add video" />
                  <p className="tapText">
                    Tap to Upload
                    <br /> Video
                  </p>
                  <div className={video.length > 0 ? "sealGreen" : "seal"}>
                    {video.length} video(s)
                  </div>
                </span> */}

              {/* <span className="fileInput">
                  <input
                    type="file"
                    accept=".mp3, .wma, .aac, .ogg, .flac, .pcm, .wav, .aiff, .alac"
                    className="inputfile"
                    id="audio_url"
                    onChange={handleAudio}
                    multiple
                  />
                  <img src={AudioSymbol} alt="tap to add audio" />
                  <p className="tapText">
                    Tap to Upload
                    <br /> Audio
                  </p>
                  <div className={audio.length > 0 ? "sealGreen" : "seal"}>
                    {audio.length} audio(s)
                  </div>
                </span> */}
            </div>
          </div>
        </div>
      </section>

      <section className="row sectionBgWhite mb-5">
        <div className="col-4"></div>
        <div className="col-8 pl-5">
          {/* <Link to="/stories">
            <button className="submitBtn" style={{ padding: "1vh 3vw" }}>
              Back
            </button>
          </Link>
          <button
            className="subBtn ml-3"
            // onClick={handleSubmit}

            style={{ background: "black", width: "50%" }}
          >
            Submit
          </button> */}

          <Stack horizontal tokens={{ childrenGap: 10 }}>
            <Link to="/stories">
              <DefaultButton text="Back" allowDisabledFocus disabled={on} checked={on} />
            </Link>



            <DefaultButton text="Submit" onClick={handleSubmit} allowDisabledFocus checked={on}
              style={{ background: '#000', color: '#fff' }}

            />

          </Stack>


        </div>
      </section>
    </div>
  );
};
export default withStoryApi(MAddStories);
