import React from "react";
import { Row, Col } from "react-bootstrap";
import about0 from "../../../assests/image/about_image.webp";
import about1 from "../../../assests/image/abtImg1.webp";
import about2 from "../../../assests/image/abtImg2.webp";
import about3 from "../../../assests/image/abtImg3.webp";
import about4 from "../../../assests/image/abtImg4.webp";
import about5 from "../../../assests/image/abtImg5.webp";
import Header from "../../../components/header/tablet/theader";
import Footer from "../../../components/header/tablet/theader";
import Btn from "../../../components/mainBtn/mainBtn";
import "./about.css";
import { Link } from "react-router-dom";
import { CompoundButton, Stack } from "office-ui-fabric-react";

const About = () => {
  return (
    <div>
      <Row style={{ margin: 0 }}>
        <Col md={12} className={"about__top__col"}>
          <div className="body__wrapper">
            <Row>
              <Col md={8} style={{ color: "#FFFFFF" }}>
                <div>
                  <h1
                    style={{
                      lineHeight: "78px",
                      fontWeight: "bold",
                      fontSize: "64px",
                      marginBottom: "12.5vh",
                      fontFamily: "Montserrat"
                    }}
                  >
                    About the Nigeria-Biafra War Memories Project
                  </h1>
                  <p
                    style={{
                      color: "#ffffff",
                      lineHeight: "40px",
                      letterSpacing: "0.01em",
                      fontSize: "24.7219px",
                      fontFamily: "Poppins"
                    }}
                    className={"about__page__p"}
                  >
                    Nigeria-Biafra War Memories (NBWM) seeks to create, with
                    your help, a most comprehensive understanding of the
                    condition of war memorials. It will help us plan for the
                    future conservation of war memorials to ensure they are fit
                    to commemorate our loved ones recorded upon them.
                  </p>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <div className="body__wrapper">
        <Row style={{ margin: 0, padding: "15.8vh 0" }}>
          <Col md={5} style={{ padding: "0", margin: "0" }}>
            <div style={{ paddingTop: "4vh" }}>
              <img
                src={about3}
                alt="our record image"
                width="350"
                height="250"
              />
            </div>
          </Col>
          <Col md={7} style={{ padding: "0", margin: "0" }}>
            <div>
              <h5 className={"about__headers"}>
                A Record of those who were involved in the Nigeria - Biafra
                Civil War
              </h5>
              <p style={{ color: "#000000" }} className={"about__page__p"}>
                This is a national biographical database initiative that allows
                researchers, enthusiasts, and veterans and their families to
                explore, contribute to, and share the records and stories of
                those who served or were caught in-between the events of the
                war.
                <br />
              </p>
              <p>
                Become part of the NBWM volunteer community at your own pace, in
                your own time. You can visit your local war memorials, take
                photographs and report on their condition on War Memorials
                Online.
              </p>
            </div>
          </Col>
        </Row>
      </div>
      <Row style={{ margin: 0, background: "black", color: "white" }}>
        <div className="body__wrapper">
          <Row style={{ margin: 0, padding: "15.8vh 0" }}>
            <Col md={7} style={{ padding: "0", margin: "0" }}>
              <div>
                <h5 className={"about__headers"}>
                  Your Place for Stories and Commemoration
                </h5>
                <p className={"about__page__p"}>
                  The information on this website is added and edited by members
                  of the public. We invite you
                  <br /> to start adding information about your local war
                  memorials. You can participate by going to <br />
                  <Link to="/searchperson">Person search</Link> and looking up
                  data on our records and adding to it. We are very grateful for
                  all contributions, but photographs and condition reports are
                  especially helpful.
                </p>
              </div>
            </Col>
            <Col
              md={5}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "0",
                margin: "0"
              }}
            >
              <div style={{ width: "fit-content" }}>
                <img
                  src={about2}
                  alt="your stories image"
                  width="350"
                  height="250"
                />
              </div>
            </Col>
          </Row>
        </div>
      </Row>
      <Row style={{ margin: 0, background: "#ffffff", color: "#000000" }}>
        <div className="body__wrapper">
          <Row style={{ margin: 0, padding: "15.8vh 0" }}>
            <Col md={5} style={{ padding: "0", margin: "0" }}>
              <div>
                <img
                  src={about4}
                  alt="Join community image"
                  width="350"
                  height="250"
                />
              </div>
            </Col>
            <Col md={7} style={{ padding: "0", margin: "0" }}>
              <div>
                <h5 className={"about__headers"}>
                  Join the volunteer community
                </h5>
                <p
                  className={"about__page__p"}
                  style={{ marginBottom: "12vh" }}
                >
                  Become part of the Nigeria - Biafra War Memories volunteer
                  community at your own pace, in your own time. You can visit
                  your local war memorials, take photographs and report on their
                  condition on this portal
                </p>
                <a href="mailto:kedu@centreformemories.org">
                  <CompoundButton
                    secondaryText="Let us show you how you can help"
                    style={{
                      background: "#000000",
                      color: "#FFFFFF",
                      width: 281
                    }}
                    className="hoverLink"
                  >
                    Contact Us
                  </CompoundButton>
                </a>
              </div>
            </Col>
          </Row>
        </div>
      </Row>
      <Row style={{ margin: 0, background: "black", color: "white" }}>
        <div className="body__wrapper">
          <Row style={{ margin: 0, padding: "15.8vh 0" }}>
            <Col md={7} style={{ padding: "0", margin: "0" }}>
              <div>
                <h5 className={"about__headers"}>Help Grow Our Records</h5>
                <p className={"about__page__p"}>
                  We need your help to ensure the records are properly curated.
                  Thanks to your overwhelming support we currently have a few
                  records and its growing by the day. You can browse the current
                  records <Link to="/searchperson">here</Link> and upload your
                  content including photos and condition reports to help
                  create a complete picture of all war memorials across the board.
                  If you cannot find a record or memorial then we would be most
                  grateful if you could add the details.
                </p>
              </div>
            </Col>
            <Col
              md={5}
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                padding: "0",
                margin: "0"
              }}
            >
              <div>
                <img
                  src={about5}
                  alt="Help grow record image"
                  width="350"
                  height="250"
                />
              </div>
            </Col>
          </Row>
        </div>
      </Row>
    </div>
  );
};

export default About;
