import React, { useState, useEffect } from "react";
import { Row, Container, Col } from "react-bootstrap";
import "./dFaq.css";
import { Link } from "react-router-dom";
import QANDA from "../quetionsAndAnswers";
import Answers from "../components/Answers";
var $ = window.$;

const Faq = props => {
  var [showHide, setShowHide] = useState({ visibleResponses: {} });

  const toggle = index => {
    const exists = showHide.visibleResponses[index];

    if (exists) {
      delete showHide.visibleResponses[index];
    } else {
      showHide.visibleResponses[index] = true;
    }

    setShowHide({ visibleResponses: showHide.visibleResponses });
  };

  useEffect(() => {}, [showHide.visibleResponses]);

  return (
    <div>
      <Row
        style={{
          padding: "0",
          margin: "0",
          color: "white",
          background: "black"
        }}
      >
        <div className="body__wrapper">
          <div className="row">
            <Col md={3} style={{ marginTop: "11.3vh" }}>
              <h3 className={"participate__header"}>FAQ</h3>
            </Col>
            <div
              className="col-md-9"
              style={{ paddingLeft: "10%", marginTop: "11.3vh" }}
            >
              <div className="row">
                {/* <Col
                  md={12}
                  style={{ paddingLeft: "10%", marginTop: "11.3vh" }}
                  id=""
                >
                  <h2
                    className={"participate__header"}
                    style={{ marginBottom: "4vh" }}
                  >
                    What is the Nigeria - Biafra War Memories?
                  </h2>
                  <p
                    style={{
                      fontsize: "24px",
                      lineHeight: "40px",
                      letterSpacing: "0.01em"
                    }}
                  >
                    Find out more about the project on the{" "}
                    <Link to="/about">About the project</Link>
                  </p>
                </Col> */}
                {QANDA.map((qst, index) => {
                  return (
                    <Col
                      md={12}
                      style={{
                        paddingLeft: "10%",
                        marginBottom: "10vh"
                      }}
                      key={"QandA" + index}
                    >
                      <div className="row">
                        <div className="col-md-11">
                          <div
                            className="participate__header"
                            onClick={() => toggle(index)}
                          >
                            <h2 className={"participate__header"}>
                              {qst.question}
                            </h2>
                          </div>
                        </div>
                        <div className="col-md-1 icon_style">
                          <i
                            className="fas fa-chevron-down"
                            onClick={() => toggle(index)}
                          ></i>
                        </div>
                      </div>

                      {showHide.visibleResponses[index] && (
                        <Answers ans={qst.answer} index={index} key={index} />
                      )}
                    </Col>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </Row>
    </div>
  );
};

export default Faq;
