import React from "react";
import DesktopAddPerson from "./desktop/dAddPerson";
import MobileAddPerson from "./mobile/mAddPerson";
import TabletAddPerson from "./tablet/tAddPerson";
import { MOBILE, TABLET } from "../../resolution";
// window.dropify = require('./dropify.min.js')

export default props => {
  if (props.resolution === MOBILE) {
    return <MobileAddPerson props={props} />;
  } else if (props.resolution === TABLET) {
    return <TabletAddPerson props={props} />;
  } else {
    return <DesktopAddPerson props={props} />;
  }
};
