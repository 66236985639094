import React, { useState, useEffect } from "react";
import "./tProfile.css";
import ImgProfile from "../../../assests/image/01.webp";
import story1 from "../../../assests/image/story1.webp";
import { CompoundButton } from "office-ui-fabric-react";
import { Link } from "react-router-dom";
import { GetPersonApi } from "../../../utils/apis";
import NoImage from "../../../assests/image/no-image.webp";
const Profile = props => {
  var [data, setData] = useState();

  const GetPerson = () => {
    GetPersonApi(props.location.state)
      .then(res => {
        setData(res.data.story.archive);
        return res;
      })
      .catch(err => {
        return err;
      });
  };

  useEffect(() => {
    GetPerson();
  }, []);

  return (
    <div style={{ paddingLeft: "3.4rem" }}>
      <div className="row" style={{ margin: 0, marginTop: "7%" }}>
        <div className="col-md-6">
          <h3 className="name">{data && data.archive_name}</h3>
          <div className="personDetails">
            <p>
              <b style={{ marginRight: "4vw" }}>State of origin:</b>
              <span>
                {data && data.state === "undefined"
                  ? "unknown"
                  : data && data.state}
              </span>
            </p>
            <p>
              <b style={{ marginRight: "4.2vw" }}>Marital Status:</b>
              <span>
                {data && data.marital_status === "undefined"
                  ? "unknown"
                  : data && data.marital_status}
              </span>
            </p>
            <p>
              <b style={{ marginRight: "5.7vw" }}>Occupation:</b>
              <span>
                {data && data.post_war_occupation === "undefined"
                  ? "unknown"
                  : data && data.post_war_occupation}
              </span>
            </p>
            <p>
              <b style={{ marginRight: "8.5vw" }}>Status:</b>
              <span>
                {data && data.status === "undefined"
                  ? "unknown"
                  : data && data.status}
              </span>
            </p>
            <p>
              <b style={{ marginRight: "4.5vw" }}>Year of Death:</b>
              <span>
                {data && data.year_of_death === "undefined"
                  ? "still alive"
                  : data && data.year_of_death}
              </span>
            </p>
            <p>
              <b style={{ marginRight: "3.8vw" }}>Cause of Death:</b>
              <span>
                {data && data.cause_of_death === "undefined"
                  ? "still alive"
                  : data && data.cause_of_death}
              </span>
            </p>
            <p>
              <b style={{ marginRight: "7vw" }}>Relatives:</b>
              <span>
                {(data && data.relatives) === "undefined"
                  ? "none"
                  : data && data.relatives}
              </span>
            </p>
          </div>
        </div>

        <div className="col-md-6">
          <div>
            <img
              src={
                data && data.archive_picture_url === "undefined"
                  ? NoImage
                  : data && data.archive_picture_url
              }
              alt="profile"
              className="imgWrap"
            />
          </div>
        </div>
      </div>

      <div className="row" style={{ margin: 0 }}>
        <div className="col-md-12" style={{ marginTop: "10rem" }}>
          <h3 className="name">Stories about this person</h3>
        </div>
      </div>

      <div className="row mt-4" style={{ margin: 0 }}>
        <div className="col-md-5">
          <div className="row my-4" style={{ margin: 0 }}>
            <div className="col-7 d-flex align-items-center">
              <div>
                <p className="storyHeader">How i buried my son to save him</p>
                <p className="storyBrief">
                  AudioNova is a private equity owned hearing and retail group
                </p>
              </div>
            </div>
            <div className="col-5">
              <div>
                <img src={story1} alt="story" className="smallImg" />
              </div>
              <p className="report ml-5">Report this Photo</p>
            </div>
          </div>

          <div className="row my-4" style={{ margin: 0 }}>
            <div className="col-7 d-flex align-items-center">
              <div>
                <p className="storyHeader">How i buried my son to save him</p>
                <p className="storyBrief">
                  AudioNova is a private equity owned hearing and retail group
                </p>
              </div>
            </div>
            <div className="col-5">
              <div>
                <img src={story1} alt="story" className="smallImg" />
              </div>
              <p className="report ml-5">Report this Photo</p>
            </div>
          </div>
        </div>

        <div className="col-md-7 d-flex justify-content-center align-items-center ">
          <div style={{ marginLeft: "16vw" }}>
            <h3 className="questionTag mb-5">
              Have any story about Micheal Onosode?
            </h3>
            <Link to="/addstory">
              <CompoundButton
                style={{ background: "#000000", color: "#FFFFFF", width: 281 }}
                className="hoverLink"
                secondaryText="Add story"
              >
                Contribute
              </CompoundButton>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
