import React, { useEffect } from "react";
import "./report.css";
import "./bootstrap3-wysihtml5.min.css";
import "./bootstrapp-theme.min.css";
import { Link } from "react-router-dom";
var $ = {};
const Report = props => {
  const setWyisyg = id => {
    $ = window.$;
    $(id).wysihtml5({
      toolbar: {
        fa: true
      }
    });
  };

  useEffect(() => {
    setWyisyg("#txtexp");
  });

  return (
    <div style={{ marginTop: "7%" }}>
      <div className="row" style={{ margin: 0, paddingLeft: "3.3rem" }}>
        <div className="col-12">
          <h2 className="addTitle">Report Inappropriate Pictures</h2>
        </div>
      </div>

      <div className="row" style={{ margin: 0, paddingLeft: "3.3rem" }}>
        <div className="col-md-7">
          <div className="grayBox pl-4 pt-5 box">
            <div className="radio">
              <label>
                <input type="radio" name="reports" />
                It’s a spam
              </label>
            </div>
            <div className="radio">
              <label>
                <input type="radio" name="reports" />I don’t like it
              </label>
            </div>
            <div className="radio">
              <label>
                <input type="radio" name="reports" />
                It’s Pornographic
              </label>
            </div>
          </div>
        </div>
        <div className="col-md-5 d-flex align-items-center justify-content-center">
          <p className="sideTitle ">
            Report
            <br />
            <span className="subs">
              (Choose a reason for reporting this picture)
            </span>
          </p>
        </div>
      </div>

      <div
        className="row"
        style={{
          margin: 0,
          borderTop: "1px solid #F3F2F1",
          paddingLeft: "3.3rem",
          marginTop: "3.5%",
          paddingTop: "3.5%"
        }}
      >
        <div className="col-md-7">
          <div className="grayBox pl-4 pt-5 box" style={{ height: "55vh" }}>
            <div className="shade d-flex align-items-center justify-content-center">
              <div className="radio">
                <label className="mt-3">
                  <input type="radio" name="reports" />I don’t think it belongs
                  here
                </label>
              </div>
            </div>
            <div className="" style={{ width: "93%" }}>
              <textarea
                rows="5"
                className="textBox wysihtml5-editor placeholder"
                id="txtexp"
                placeholder="State your reasons..."
                // onChange={handleContent}
                style={{ color: "black" }}
              ></textarea>
            </div>
          </div>
        </div>
        <div className="col-md-5 d-flex align-items-center justify-content-center">
          <p className="sideTitle ">Reasons not Classified</p>
        </div>
      </div>

      <div className="row" style={{ margin: 0, marginBottom: "3.3rem" }}>
        <div className="col-12">
          <div className="d-flex justify-content-center">
            <Link to="/">
              <button className="submitBtn my-4">Back</button>
            </Link>
            <button className="subBtn my-4 ml-3">Submit</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Report;
