import React, { useState, useEffect } from "react";
import ImgProfile from "../../../assests/image/01.webp";
import story1 from "../../../assests/image/story1.webp";
import { CompoundButton } from "office-ui-fabric-react";
import { Link } from "react-router-dom";
import { GetPersonApi } from "../../../utils/apis";
import NoImage from "../../../assests/image/no-image.webp";
import Loader from "../../../assests/image/loading.gif";
import "./mProfile.css";
const MProfile = ({ props }) => {
  var [data, setData] = useState();
  var [load, setLoad] = useState(true);
  const GetPerson = () => {
    GetPersonApi(props.match.params.id)
      .then(res => {
        setLoad(false);
        setData(res.data.story.archive);
        return res;
      })
      .catch(err => {
        return err;
      });
  };

  useEffect(() => {
    GetPerson();
  }, []);
  return (
    <div>
      <div className="row sectionBgWhite">
        <div className="col-12 topSpace">
          <h3
            className="titleMobBlack mb-5"
            style={{ textTransform: "capitalize" }}
          >
            {data && data.archive_name}
          </h3>
        </div>
      </div>

      <section className="row sectionBgWhite pb-4">
        <div className="col-12">
          <div className="mb-5">
            {load ? (
              <div className="text-center">
                <img src={Loader} alt="loading" width="200" height="200" />
              </div>
            ) : (
              <img
                src={data && data.archive_picture_url}
                alt="profile"
                className="profileImg"
              />
            )}
          </div>
          {load ? (
            <div></div>
          ) : (
            <div>
              <div className="details">
                <span className="profileKey">State of Origin:</span>
                <span className="profileValue">
                  {data && data.current_location}
                </span>
              </div>
              <div className="details">
                <span className="profileKey">Marital Status:</span>
                <span className="profileValue">
                  {data && data.marital_status}
                </span>
              </div>
              <div className="details">
                <span className="profileKey">Occupation:</span>
                <span className="profileValue">
                  {data && data.post_war_occupation === "undefined"
                    ? "unknown"
                    : data && data.post_war_occupation}
                </span>
              </div>
              <div className="details">
                <span className="profileKey">Status:</span>
                <span className="profileValue">{data && data.status}</span>
              </div>
              <div className="details">
                <span className="profileKey">Year of Death:</span>
                <span className="profileValue">
                  {data && data.year_of_death}
                </span>
              </div>
              <div className="details">
                <span className="profileKey">Cause of Death:</span>
                <span className="profileValue">
                  {(data && data.relatives) === "undefined"
                    ? "none"
                    : data && data.relatives}
                </span>
              </div>
              <div className="details">
                <span className="profileKey">Relatives:</span>
                <span className="profileValue">{data && data.relatives}</span>
              </div>
            </div>
          )}
        </div>
      </section>

      <section className="row sectionBgWhite">
        <div className="col-12">
          <h3 className="titleMobBlack mb-5">
            Stories about this
            <br /> person
          </h3>
        </div>
      </section>

      <section className="row sectionBgWhite mb-3">
        <div className="col-12">
          <div className="row sectionBgWhite profileStory pt-2">
            <div className="col-8">
              <h3>How I Buried My Son To Save Him</h3>
              <p>
                AudioNova is a private equity owned hearing and retail group
              </p>
            </div>
            <div className="col-4">
              <div>
                <img src={story1} alt="story" className="imgSP" />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="row sectionBgWhite mb-3">
        <div className="col-12">
          <div className="row sectionBgWhite profileStory pt-2">
            <div className="col-8">
              <h3>How I Buried My Son To Save Him</h3>
              <p>
                AudioNova is a private equity owned hearing and retail group
              </p>
            </div>
            <div className="col-4">
              <div>
                <img src={story1} alt="story" className="imgSP" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="row sectionBgWhite mt-3">
        <div className="col-12">
          <h2 className="titleMobBlack">
            Have any story about Micheal Onosode?
          </h2>
        </div>
        <div className="col-12">
          <div className="mt-4 mb-5">
            <Link to="/addstory">
              <CompoundButton
                style={{ background: "#000000", color: "#FFFFFF", width: 281 }}
                className="hoverLink"
                secondaryText="Add story"
              >
                Contribute
              </CompoundButton>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};
export default MProfile;
