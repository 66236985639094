import React, { useEffect } from "react";
import {
  TextField,
  Dropdown,
  ResponsiveMode
} from "office-ui-fabric-react/lib";
import "../bootstrap3-wysihtml5.min.css";
import "../bootstrapp-theme.min.css";
import { Link } from "react-router-dom";
import Loader from "../../../assests/image/loading.gif";
import "../dropify.css";
import withAddPersonApi from "../addPersonUtils";
import ImgSymbol from "../../../assests/image/photowatermark.png";
const MAddPerson = props => {
  var {
    on,
    handleChange,
    handleFiles,
    handleImg,
    handleAudio,
    handleVideo,
    submit,
    profileOptions,
    statusOptions,
    storyTypes,
    categoryOptions,
    picture
  } = props;

  return (
    <div>
      <div className="row sectionBgWhite">
        <div className="col-12 topSpace">
          <h2 className="titleMobBlack">Please fill out the form below</h2>
          <p className="addStoriesHeader mt-5">Person Photograph</p>
        </div>
      </div>

      <section className="row sectionBgWhite graySpace">
        <div className="col-12">
          <div>
            <input
              type="file"
              id="input-file-before"
              className="dropify"
              onChange={handleFiles}
              data-max-file-size-preview="3M"
              data-max-file-size="3M"
              style={{ fontFamily: "Poppins" }}
            />
          </div>
        </div>
      </section>
      <hr />
      <div className="row sectionBgWhite">
        <div className="col-12">
          <p className="addStoriesHeader mt-2">Personal Information</p>
        </div>
      </div>
      <section className="row sectionBgWhite graySpace py-4">
        <div className="col-12">
          <TextField
            label="Name"
            id="archive_name"
            required
            onChange={handleChange}
          />
          <TextField
            label="Age"
            id="age"
            styles={{ fieldGroup: { width: "50%" } }}
            onChange={handleChange}
          />
          <TextField
            label="Marital Status"
            id="marital_status"
            styles={{ fieldGroup: { width: "50%" } }}
            onChange={handleChange}
          />
          <TextField
            label="State"
            id="state"
            onChange={handleChange}
            required
          />
        </div>
      </section>
      <hr />
      <div className="row sectionBgWhite">
        <div className="col-12">
          <p className="addStoriesHeader mt-2">Personal Information</p>
        </div>
      </div>

      <section className="row sectionBgWhite graySpace py-4">
        <div className="col-12">
          <Dropdown
            label="Profile"
            id="category"
            onChange={handleChange}
            options={profileOptions}
            defaultSelectedKey={"1"}
            responsiveMode={ResponsiveMode.large}
            styles={{ dropdown: { width: "60%" } }}
          />
          <TextField
            label="Title"
            id="title"
            styles={{ fieldGroup: { width: "50%" } }}
            onChange={handleChange}
          />
          <TextField
            label="Pre-war Occupation"
            id="pre_war_occupation"
            onChange={handleChange}
          />
          <TextField
            label="Post-war Occupation"
            id="post_war_occupation"
            onChange={handleChange}
          />
          <TextField
            label="Current Location (If alive)"
            id="current_location"
            onChange={handleChange}
          />
        </div>
      </section>

      <hr />
      <div className="row sectionBgWhite">
        <div className="col-12">
          <p className="addStoriesHeader mt-2">Status</p>
        </div>
      </div>

      <section className="row sectionBgWhite graySpace py-4">
        <div className="col-12">
          <Dropdown
            label="Status"
            id="status"
            onChange={handleChange}
            options={statusOptions}
            responsiveMode={ResponsiveMode.large}
            styles={{ dropdown: { width: "60%" } }}
            defaultSelectedKey={"1"}
          />
          <TextField
            label="Year of Death"
            id="year_of_death"
            styles={{ fieldGroup: { width: "50%" } }}
            onChange={handleChange}
          />
          <TextField
            label="Cause of Death"
            placeholder="cause of death"
            id="cause_of_death"
            onChange={handleChange}
          />
        </div>
      </section>
      <hr />
      <div className="row sectionBgWhite">
        <div className="col-12">
          <p className="addStoriesHeader mt-2">Family</p>
        </div>
      </div>

      <section className="row sectionBgWhite graySpace py-4">
        <div className="col-12">
          <TextField label="Spouse" id="spouse" onChange={handleChange} />
          <TextField label="Children" id="children" onChange={handleChange} />
          <TextField label="Relatives" id="relatives" onChange={handleChange} />
        </div>
      </section>
      <hr />
      <div className="row sectionBgWhite">
        <div className="col-12">
          <p className="addStoriesHeader mt-2">
            Experience <span>(Optional)</span>
            <br />
            <span> (Add a story, image, video and audio)</span>
          </p>
        </div>
      </div>
      <section className="row sectionBgWhite graySpace py-4">
        <div className="col-12">
          {/* <TextField
            label="Title of Story"
            id="title_of_story"
            styles={{ fieldGroup: { width: "60%" } }}
            onChange={handleChange}
          />
          <Dropdown
            label="Conflict"
            placeholder="Pick a conflict (Opt)"
            id="story_type"
            styles={{ dropdown: { width: "60%" } }}
            // onChange={handleChange}
            options={storyTypes}
          />
          <TextField
            label="Author"
            id="author"
            onChange={handleChange}
          /> */}
          <div className="mt-3">
            {/* <h3 className="enterStoryHeader">Story</h3> */}
            <textarea
              rows="8"
              className="textBox wysihtml5-editor placeholder"
              id="txtexp"
              style={{ color: "black" }}
            ></textarea>
          </div>
        </div>
      </section>
      <hr />
      <div className="row sectionBgWhite">
        <div className="col-12">
          <p className="addStoriesHeader mt-2">
            Media <span>(Add images, video or audio)</span>
          </p>
        </div>
      </div>

      <section
        className="row sectionBgWhite graySpace"
        style={{ marginBottom: "1rem" }}
      >
        <div className="col-12">
          <div className="d-flex justify-content-center align-items-center">
            <div
              className="smallBox d-flex justify-content-center align-items-center"
              style={{ width: "100%" }}
            >
              <span className="fileInput">
                <input
                  type="file"
                  accept=".jpg, .jpeg, .png"
                  className="inputfile"
                  onChange={handleImg}
                  id="image_url"
                  multiple
                />
                <img src={ImgSymbol} alt="tap to add" />
                <p className="tapText">
                  Tap to Upload
                  <br /> Image
                </p>
                <div className={picture.length > 0 ? "sealGreen" : "seal"}>
                  {picture.length} image(s)
                </div>
              </span>

              {/* <span className="fileInput mx-5">
                  <input
                    type="file"
                    accept=".m4a, .mp4, .f4v, .m4b, .m4r, f4b, .mov, .3gp, .wmv, .mpeg, .avi, .webm, .flv, .hdv"
                    className="inputfile"
                    id="video_url"
                    onChange={handleVideo}
                    multiple
                  />
                  <img src={VideoSymbol} alt="tap to add video" />
                  <p className="tapText">
                    Tap to Upload
                    <br /> Video
                  </p>
                  <div className={video.length > 0 ? "sealGreen" : "seal"}>
                    {video.length} video(s)
                  </div>
                </span> */}

              {/* <span className="fileInput">
                  <input
                    type="file"
                    accept=".mp3, .wma, .aac, .ogg, .flac, .pcm, .wav, .aiff, .alac"
                    className="inputfile"
                    id="audio_url"
                    onChange={handleAudio}
                    multiple
                  />
                  <img src={AudioSymbol} alt="tap to add audio" />
                  <p className="tapText">
                    Tap to Upload
                    <br /> Audio
                  </p>
                  <div className={audio.length > 0 ? "sealGreen" : "seal"}>
                    {audio.length} audio(s)
                  </div>
                </span> */}
            </div>
          </div>
        </div>
      </section>

      <section className="row sectionBgWhite mb-5">
        <div className="col-4">
          {on ? (
            <span
              style={{ display: "inline-block" }}
              className="my-auto d-flex justify-content-center"
            >
              <img src={Loader} alt="loading" width="50" height="50" />
            </span>
          ) : null}
        </div>
        <div className="col-8">
          <Link to="/stories">
            <button className="submitBtn" style={{ padding: "1vh 2vw" }}>
              Back
            </button>
          </Link>
          <button
            className="subBtn ml-3"
            onClick={submit}
            disabled={on}
            style={{ background: on ? "gray" : "black", width: "50%" }}
          >
            Submit
          </button>
        </div>
      </section>
    </div>
  );
};

export default withAddPersonApi(MAddPerson);
