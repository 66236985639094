import React from "react";
import "./alertCard.css";

const AlertCard = ({ error, message }) => {
  return (
    <div
      className={error ? "redCard" : "card"}
      style={{ display: message === "" ? "none" : "block" }}
    >
      {message}
    </div>
  );
};

export default AlertCard;
