import  React from 'react';
import {DESKTOP, MOBILE, TABLET} from '../../resolution';
import MobileDigitalize from './mobile/mDigitalGuideline';
import DesktopDigitalize from './digitalGuideline';

export default (props) =>{
    if(props.resolution === MOBILE){
        return<MobileDigitalize />
    }
    else if(props.resolution === TABLET){
        return <DesktopDigitalize />
    }else{
        return <DesktopDigitalize props={props} />
    }
} 