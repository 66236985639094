import React, {useState, useEffect} from 'react';
import {AddStoryApi} from '../../utils/apis';



var $ = {};
export default (Component) => ({props}) => {
    const [data, setData] = useState({});
    const [picture, setPicture] = useState([]);
    const [audio, setAudio] = useState([]);
    const [video, setVideo] = useState([]);
    const [on, setOn] = useState(false);
    var [errMessage, setErrMessage] = useState("");
    const uploadData = new FormData();

    useEffect(() => {
        setWyisyg("#txtexp");
        $(document).ready(function () {
            // Basic
            $(".dropify").dropify();

            // Translated
            $(".dropify-fr").dropify({
                messages: {
                    default: "Glissez-déposez un fichier ici ou cliquez",
                    replace: "Glissez-déposez un fichier ou cliquez pour remplacer",
                    remove: "Supprimer",
                    error: "Désolé, le fichier trop volumineux"
                }
            });

            // Used events
            var drEvent = $("#input-file-events").dropify();

            drEvent.on("dropify.afterClear", function (event, element) {
                alert("File deleted");
            });

            drEvent.on("dropify.errors", function (event, element) { });

            var drDestroy = $("#input-file-to-destroy").dropify();
            drDestroy = drDestroy.data("dropify");
            $("#toggleDropify").on("click", function (e) {
                e.preventDefault();
                if (drDestroy.isDropified()) {
                    drDestroy.destroy();
                } else {
                    drDestroy.init();
                }
            });
        });
    }, []);

    var storyTypes = [
        { key: "1", text: "conflict1" },
        { key: "2", text: "conflict2" },
        { key: "3", text: "conflict3" },
        { key: "4", text: "conflict4" }
    ];

    const setWyisyg = id => {
        $ = window.$;
        $(id).wysihtml5({
            toolbar: {
                fa: true
            }
        });
    };

    const handleChange = (e, b) => {
        if (typeof b === "string") {
            setData({ ...data, [e.target.id]: e.target.value });
        } else {
            setData({ ...data, [e.target.id]: b.key });
            // if (data.author.length > 0 && data.title.length > 0) {
            //   setEnable(true);
            // }
        }
    };

    const handleImg = e => {
        const reader = new FileReader();
        const files = e.target.files;
        let imgArr = [];
        for (let i = 0; i < e.target.files.length; i++) {
            var filesize = (e.target.files[i].size / 1024 / 1024).toFixed(4);
            if (filesize <= 3) {
                imgArr.push(e.target.files[i]);
                reader.onload = () => {
                    setPicture(imgArr);
                };
                reader.readAsDataURL(files[0]);
            } else {
                setErrMessage("Image is bigger than 3MB");
                setTimeout(() => {
                    setErrMessage("");
                }, 2000);
            }
        }
    };

    const handleVideo = e => {
        const reader = new FileReader();
        const files = e.target.files;
        let videoArr = [];
        for (let i = 0; i < e.target.files.length; i++) {
            videoArr.push(e.target.files[i]);
        }
        reader.onload = () => {
            setVideo(videoArr);
        };

        reader.readAsDataURL(files[0]);
    };

    const handleAudio = e => {
        const reader = new FileReader();
        const files = e.target.files;
        let audioArr = [];
        for (let i = 0; i < e.target.files.length; i++) {
            audioArr.push(e.target.files[i]);
        }
        reader.onload = () => {
            setAudio(audioArr);
        };

        reader.readAsDataURL(files[0]);
    };

    const handleSubmit = async e => {
        e.preventDefault();
        var message = $("#txtexp").val();

        setData({ ...data, content: message });
        if (data.title && data.title.length > 0) {
            if (data.author && data.author.length > 0) {
                if (message.length > 0) {
                    setErrMessage("");
                    setOn(true);
                    uploadData.append("title", data.title);
                    uploadData.append("story_type", data.story_type);
                    uploadData.append("author", data.author);
                    uploadData.append("content", message);

                    picture.forEach(image_file => {
                        uploadData.append("picture_url[]", image_file);
                    });

                    video.forEach(video_file => {
                        uploadData.append("video_url[]", video_file);
                    });

                    audio.forEach(audio_file => {
                        uploadData.append("audio_url[]", audio_file);
                    });

                    let res = await AddStoryApi(uploadData);
                    if (res.status === 200) {
                        setErrMessage("story added successfully");
                        setTimeout(() => {
                            props.history.push("/stories");
                        }, 1000);
                    } else {
                        setOn(false);
                    }
                } else {
                    setErrMessage("enter story content");
                }
            } else {
                setErrMessage("enter author of story");
            }
        } else {
            setErrMessage("enter story title");
        }
    };


    return <Component 
        handleSubmit={handleSubmit}
        handleAudio={handleAudio}
        handleVideo={handleVideo}
        handleImg={handleImg}
        handleChange={handleChange}
        picture={picture}
        on={on}
        errMessage={errMessage}
        {...props}
    />;
};