import React, { useState } from 'react';
import { SearchPersonApi, GetPersonApi } from '../../utils/apis';


export default (Component) => () => {
    var [persons, setPersons] = useState({ count: 0, result: [] });
    var [searching, setSearching] = useState({ ongoing: false, fetching: false });
    var [message, setMessage] = useState("");

    const handleSearch = e => {
        if (e) {
            let data = {};
            if (e.target.value.length > 2) {
                setSearching({ ongoing: true, fetching: true });
                data = {
                    search_value: e.target.value
                };
                SearchPersonApi(data)
                    .then(result => {
                        setSearching({ ongoing: true, fetching: false });
                        setPersons({ count: result.data.archive.length, result: result.data.archive });
                        // if (e.target.value.length > 2 && result.data.archive.length === 0) {
                        //     setMessage("Not found");
                        // }
                    })
                    .catch(error => {
                        //show no internet connection here
                    });
            } else {
                if (searching) {
                    setSearching({ ongoing: false, fetching: false });
                }
            }
        }else{
            setSearching({ ongoing: false, fetching: false });
        }
    };

    return <Component handleSearch={handleSearch} message={message} searching={searching} persons={persons} />;
};

