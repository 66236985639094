import React from 'react'

const MainBtn = (props) => {
    return (
        <button onClick={props.onClick} className="btn" style={{ color: props.color, background: props.bgColor, textAlign: "left", width: "20.5vw", height: "10.3vh" }}>
            <p style={{ fontSize: "14px", lineHeight: "19px", padding: 0, margin: 0, paddingTop: 4, fontWeight: 600, fontFamily: "Segoe UI" }}>{props.name}</p>
            <small style={{ fontSize: "12px", lineHeight: "16px", padding: 0, margin: 0, fontFamily: "Segoe UI" }}>{props.text}</small>
        </button>
    )
}

export default MainBtn