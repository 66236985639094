import React, { useState, useEffect } from "react";
import { GetStoriesApi } from "../../utils/apis";

export default Component => ({ props }) => {
  const [stories, setStories] = useState({
    page: 1,
    size: 10,
    visitedPage: [],
    totalresult: 0,
    data: [],
    liveData: []
  });
  const [load, setLoad] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    GetStories(1, 10, true);
  }, []);

  const MoveToPrevious = page => {
    var totalAvailable = stories.data.length;
    var startIndex = Math.floor(
      (page - Math.floor(totalAvailable / stories.size)) * stories.size
    );
    var stopIndex = stories.size * page;
    setStories({
      ...stories,
      page: page,
      liveData: [...stories.data].slice(startIndex, stopIndex - 1)
    });
    setLoad(false);
  };

  const MoveToNextPage = page => {
    GetStories(page, stories.size);
  };
  const GetStories = (page = 1, size = 10, init = false) => {
    setLoad(true);
    if (stories.visitedPage.indexOf(page) === -1) {
      GetStoriesApi(size, page)
        .then(result => {
          var data = result && result.data.stories;
          if (data === undefined || data === null) {
            setLoad(false);
            setError("Network Error");
          } else {
            var { current_page, total, per_page } =
              result && result.data.pagination_details;
            setStories({
              ...stories,
              page: stories.page > current_page ? stories.page : page,
              visitedPage: Array.from(new Set([...stories.visitedPage, page])),
              totalresult: total,
              data: [...stories.data, ...data],
              liveData: [...stories.data, ...data].slice(
                (page - 1) * stories.size,
                size * page
              )
            });
            setLoad(false);
          }
        })
        .catch(err => {});
    } else {
      MoveToPrevious(page);
    }
  };

  const ReportImg = () => {
    props.history.push({ pathname: "/report" });
  };

  const handleStory = id => {
    props.history.push({ pathname: `/story/${id}` });
  };

  return (
    <Component
      GetStories={GetStories}
      handleStory={handleStory}
      ReportImg={ReportImg}
      stories={stories}
      load={load}
      error={error}
      MoveToNextPage={MoveToNextPage}
      MoveToPrevious={MoveToPrevious}
    />
  );
};
