import React, { useState } from 'react';

export default (Component) => () => {
    var [showHide, setShowHide] = useState({ visibleResponses: {} });

    const toggle = (index) => {
        const exists = showHide.visibleResponses[index];
    
        if (exists) {
          delete showHide.visibleResponses[index];
        } else {
          showHide.visibleResponses[index] = true;
        }
    
        setShowHide({ visibleResponses: showHide.visibleResponses });
      }
      return <Component showHide={showHide} toggle={toggle} />
}