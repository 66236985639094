import React, { useState } from "react";
import "./App.css";
import Routers from "./Approute";
import { isMobile, isTablet } from 'react-device-detect';
import { MOBILE, DESKTOP, TABLET } from './resolution';
import ErrorBoundary from './ErrorBoundary';
var $ = window.$;
var changedWidth = 0;
var changed = false;
var currentResolution = isMobile && !isTablet ? MOBILE : isTablet ? TABLET : DESKTOP;



function App() {
  var [screenDetail, setscreenDetail] = useState({ resolution: {} });


  $(window).on('load', function () {
    var width = $(this).width();
    var height = $(this).height();
    changedWidth = width;
    changed = true;
    currentResolution = width <= 414 ? MOBILE : width > 414 && width <= 768 ? TABLET : DESKTOP;
    setscreenDetail({ resolution: { width, height } });
  });

  $(window).on('resize', function () {
    if ($(this).width() !== changedWidth) {
      var width = $(this).width();
      var height = $(this).height();
      changedWidth = width;
      changed = true;
      currentResolution = width <= 414 ? MOBILE : width > 414 && width <= 768 ? TABLET : DESKTOP;
      setscreenDetail({ resolution: { width, height } });
    }
  });



  return (
    <div className="">
      {/* <ErrorBoundary> */}
        <Routers changed={changed} resolution={currentResolution} />
      {/* </ErrorBoundary> */}
    </div>
  );
}

export default App;
