import React from "react";
import Header from "../header/tablet/theader";
import Footer from "../footer/tablet/footer";
const MobileLayout = props => {
  return (
    <div>
      <div className="navbar__wrapper">
        <Header />
      </div>
      {window.scrollTo(0, 0)}
      {props.children}
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default MobileLayout;
