import React from "react";
import { TextField, Dropdown } from "office-ui-fabric-react/lib";
import { Link } from "react-router-dom";
import "../addStory.css";
import "../dropify.css";
import "../bootstrap3-wysihtml5.min.css";
import "../bootstrapp-theme.min.css";
import ImgSymbol from "../../../assests/image/photowatermark.png";
import Loader from "../../../assests/image/loading.gif";
import AlertCard from "../../../components/alertCard/alertCard";
import withAddStoryApi  from '../addStoriyUtils';


const AddStory = props => {
  var {on, errMessage, picture, handleChange, handleImg, handleSubmit} = props;
  return (
    <div>
      <AlertCard message={errMessage} error={on ? false : true} />
      <div style={{ marginTop: "8%" }}>
        <div className="row" style={{ margin: 0, paddingLeft: "3.3rem" }}>
          <div className="col-12 addTitle">Add your stories from the war</div>
        </div>

        <div
          className="row"
          style={{ margin: 0, marginTop: "7%", paddingLeft: "3.3rem" }}
        >
          <div className="col-md-7">
            <div className="grayBox" style={{ height: "80vh" }}>
              <div
                className="row ml-2 mt-4"
                style={{ margin: 0, width: "93%" }}
              >
                <div className="col-6">
                  <TextField
                    label="Title of Story"
                    placeholder="Add a title..."
                    id="title"
                    onChange={handleChange}
                    required
                  />
                </div>
                {/* <div className="col-6">
                  <Dropdown
                    label="Conflict"
                    placeholder="Pick a conflict (Opt)"
                    id="story_type"
                    onChange={handleChange}
                    options={storyTypes}
                  />
                </div> */}

                <div className="col-6">
                  <TextField
                    label="Author"
                    placeholder="Add author name"
                    id="author"
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
              <div className="row ml-2" style={{ margin: 0, width: "93%" }}>
                <div className="col-6"></div>
              </div>
              <div className="storytext ml-4 mt-2 mb-1">Story</div>
              <div className="ml-4" style={{ width: "93%" }}>
                <textarea
                  rows="8"
                  className="textBox wysihtml5-editor placeholder"
                  id="txtexp"
                  placeholder="Tell your story"
                  // onChange={handleContent}
                  style={{ color: "black" }}
                  required
                ></textarea>
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{ paddingTop: "15vh" }}
            >
              <p className="subs">
                <span className="sideTitle">Stories</span>
                <br />
                (Add stories)
              </p>
            </div>
          </div>
        </div>

        <div
          className="row"
          style={{ margin: 0, paddingLeft: "3.3rem", marginTop: "7%" }}
        >
          <div className="col-md-7">
            <div className="grayBox d-flex justify-content-center align-items-center">
              <div className="smallBox d-flex justify-content-center align-items-center">
                <span className="fileInput">
                  <input
                    type="file"
                    accept=".jpg, .jpeg, .png"
                    className="inputfile"
                    id="image_url"
                    onChange={handleImg}
                    multiple
                  />
                  <img src={ImgSymbol} alt="tap to add" />
                  <p className="tapText">
                    Tap to Upload
                    <br /> Image
                  </p>
                  <div className={picture.length > 0 ? "sealGreen" : "seal"}>
                    {picture.length} image(s)
                  </div>
                </span>

                {/* <span className="fileInput mx-5">
                  <input
                    type="file"
                    accept=".m4a, .mp4, .f4v, .m4b, .m4r, f4b, .mov, .3gp, .wmv, .mpeg, .avi, .webm, .flv, .hdv"
                    className="inputfile"
                    id="video_url"
                    onChange={handleVideo}
                    multiple
                  />
                  <img src={VideoSymbol} alt="tap to add video" />
                  <p className="tapText">
                    Tap to Upload
                    <br /> Video
                  </p>
                  <div className={video.length > 0 ? "sealGreen" : "seal"}>
                    {video.length} video(s)
                  </div>
                </span> */}

                {/* <span className="fileInput">
                  <input
                    type="file"
                    accept=".mp3, .wma, .aac, .ogg, .flac, .pcm, .wav, .aiff, .alac"
                    className="inputfile"
                    id="audio_url"
                    onChange={handleAudio}
                    multiple
                  />
                  <img src={AudioSymbol} alt="tap to add audio" />
                  <p className="tapText">
                    Tap to Upload
                    <br /> Audio
                  </p>
                  <div className={audio.length > 0 ? "sealGreen" : "seal"}>
                    {audio.length} audio(s)
                  </div>
                </span> */}
              </div>
            </div>
          </div>
          <div className="col-md-5">
            <div
              className="d-flex align-items-center justify-content-center py-auto"
              style={{ paddingTop: "18vh" }}
            >
              <p className="subs">
                <span className="sideTitle">Media</span>
                <br />
                (Add images)
              </p>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, marginBottom: "3.3rem" }}>
          <div className="col-12">
            <div className="d-flex justify-content-center">
              <Link to="/stories">
                <button className="submitBtn my-4">Back</button>
              </Link>
              <button
                className="subBtn my-4 ml-3"
                onClick={handleSubmit}
                disabled={on}
                style={{ background: on ? "gray" : "black" }}
              >
                Submit
              </button>
              {on ? (
                <span style={{ display: "inline-block" }} className="my-auto">
                  <img src={Loader} alt="loading" width="50" height="50" />
                </span>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default withAddStoryApi(AddStory);
