import React from "react";
import DesktopStory from "./desktop/dAddStory";
import TabletStory from "./tablet/tAddStory";
import MobileStory from "./mobile/mAddStory";
import { MOBILE, TABLET } from "../../resolution";

export default props => {
  if (props.resolution === MOBILE) {
    return <MobileStory props={props} />;
  } else if (props.resolution === TABLET) {
    return <TabletStory props={props} />;
  } else {
    return <DesktopStory props={props} />;
  }
};
