import React from 'react';
import DesktopHome from './desktop/dHome';
import TabletHome from './tablet/tHome';
import MobileHome from './mobile/mHome';
import { MOBILE, TABLET } from '../../resolution';

export default (props) => {
  if (props.resolution === MOBILE) {
    return <MobileHome props={props} />
  } else if (props.resolution === TABLET) {
    return <TabletHome props={props} />
  } else {
    return <DesktopHome props={props} />
  }
};
