import React from "react";
import Desktop from "./desktop/dFaq";
import Tablet from "./tablet/faq";
import MobileHome from "./mobile/mFaq";
import { MOBILE, TABLET, DESKTOP } from "../../resolution";

export default props => {
  if (props.resolution === MOBILE) {
    return <MobileHome props={props} />;
  } else if (props.resolution === TABLET) {
    return <Tablet props={props} />;
  } else {
    return <Desktop props={props} />;
  }
};
