import React from "react";
import "./tAddperson.css";
import { TextField, Dropdown, Stack, DefaultButton } from "office-ui-fabric-react/lib";
import "../bootstrap3-wysihtml5.min.css";
import "../bootstrapp-theme.min.css";
import { string } from "prop-types";
import { AddPersonApi } from "../../../utils/apis";
import ImgSymbol from "../../../assests/image/photowatermark.png";
import AudioSymbol from "../../../assests/image/audio.webp";
import VideoSymbol from "../../../assests/image/videowatermark.png";
import { Link } from "react-router-dom";
import Loader from "../../../assests/image/loading.gif";
import "../dropify.css";
import withAddPersonApi from "../addPersonUtils";

const AddPerson = props => {
  var {
    on,
    handleChange,
    handleFiles,
    handleImg,
    handleAudio,
    handleVideo,
    submit,
    profileOptions,
    statusOptions,
    storyTypes,
    categoryOptions,
    picture
  } = props;

  return (
    <div className="" style={{ marginTop: "13vh" }}>
      <div className="my-5 mx-3" >
        <div className="row noPadding" style={{ margin: 0 }}>
          <div className="col-12 addTitle">Please fill out the form below</div>
        </div>

        <form>
          <div
            className="row"
            style={{ margin: 0, marginTop: "7%", }}
          >
            <div className="col-md-10 d-flex align-items-center ">
              <p className="sideTitle ">Person Photograph</p>
            </div>
            <div className="col-md-10">
              <div>
                <div className="dz-message needsclick" style={{ margin: 0 }}>
                  <div className="grayBox d-flex justify-content-center align-items-center">
                    <div style={{ width: "80%" }}>
                      <input
                        type="file"
                        id="input-file-before"
                        className="dropify"
                        onChange={handleFiles}
                        data-max-file-size-preview="3M"
                        data-max-file-size="3M"
                        style={{ fontFamily: "Poppins" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div
            className="row mt-5"
            style={{ margin: 0, marginTop: "4%" }}
          >
            <div className="col-md-10 d-flex align-items-center ">
              <p className="sideTitle ">Personal Information</p>
            </div>

            <div className="col-md-10">
              <div className="grayBox">
                <div
                  style={{ width: "80%", paddingTop: "2.5rem" }}
                  className="ml-4"
                >
                  <TextField
                    label="Name"
                    placeholder="Enter person name"
                    id="archive_name"
                    onChange={handleChange}
                    styles={{ root: { height: '7vh' } }}
                    required
                  />

                  <div className="row">
                    <div className="col-6">
                      <TextField
                        label="Age"
                        placeholder="Enter person age"
                        id="age"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-6">
                      <TextField
                        label="Marital Status"
                        placeholder="Enter person marital status"
                        id="marital_status"
                        onChange={handleChange}
                      />
                    </div>


                  </div>
                  <TextField
                    label="State"
                    placeholder="Enter person state of origin"
                    id="state"
                    onChange={handleChange}
                    required
                  />
                </div>
              </div>
            </div>

          </div>

          <div
            className="row"
            style={{
              margin: 0,
              marginTop: "3.5%",
              borderTop: "1px solid #F3F2F1",
              // paddingLeft: "3.3rem",
              paddingTop: "3.5%"
            }}
          >
            <div className="col-md-10 d-flex align-items-center">
              <p className="sideTitle ">Occupation</p>
            </div>
            <div className="col-md-10">
              <div className="grayBox">
                <div
                  style={{ width: "80%", paddingTop: "2.5rem" }}
                  className="ml-4"
                >
                  <div className="row">
                    <div className="col-6">
                      <Dropdown
                        placeholder="Content"
                        label="Category"
                        id="category"
                        onChange={handleChange}
                        options={categoryOptions}
                      />
                    </div>
                    <div className="col-6">
                      <TextField
                        label="Title"
                        placeholder="title"
                        id="title"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <TextField
                        label="Pre-war Occupation"
                        placeholder="pre-war Occupation"
                        id="pre_war_occupation"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-6">
                      <TextField
                        label="Post-war Occupation"
                        placeholder="post-war Occupation"
                        id="post_war_occupation"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <TextField
                        label="Current Location (If alive)"
                        placeholder="current location"
                        id="current_location"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div
            className="row"
            style={{
              margin: 0,
              marginTop: "3.5%",
              borderTop: "1px solid #F3F2F1",
              // paddingLeft: "3.3rem",
              paddingTop: "3.5%"
            }}
          >
            <div className="col-md-5 d-flex align-items-center">
              <p className="sideTitle">Status</p>
            </div>
            <div className="col-md-10">
              <div className="grayBox">
                <div
                  style={{ width: "80%", paddingTop: "2.5rem" }}
                  className="ml-4"
                >
                  <div className="row">
                    <div className="col-6">
                      <Dropdown
                        placeholder="Content"
                        label="Status"
                        id="status"
                        onChange={handleChange}
                        options={statusOptions}
                      />
                    </div>
                    <div className="col-6"></div>
                  </div>

                  <div className="row mt-5">
                    <div className="col-6">
                      <TextField
                        label="Year of Death"
                        placeholder="year of death"
                        id="year_of_death"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-6">
                      <TextField
                        label="Cause of Death"
                        placeholder="cause of death"
                        id="cause_of_death"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div
            className="row"
            style={{
              margin: 0,
              marginTop: "3.5%",
              borderTop: "1px solid #F3F2F1",
              // paddingLeft: "3.3rem",
              paddingTop: "3.5%"
            }}
          >
            <div className="col-md-10 d-flex align-items-center">
              <p className="sideTitle">Family</p>
            </div>
            <div className="col-md-10">
              <div className="grayBox">
                <div
                  style={{ width: "80%", paddingTop: "2rem" }}
                  className="ml-4"
                >
                  <div className="row mt-2">
                    <div className="col-12">
                      <TextField
                        label="Spouse"
                        placeholder="spouse"
                        id="spouse"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-12">
                      <TextField
                        label="Children"
                        placeholder="children"
                        id="children"
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-12">
                      <TextField
                        label="Relatives"
                        placeholder="relatives"
                        id="relatives"
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div
            className="row mb-5"
            style={{
              margin: 0,
              marginTop: "3.5%",
              borderTop: "1px solid #F3F2F1",
              // paddingLeft: "3.3rem",
              paddingTop: "3.5%"
            }}
          >
            <div className="col-md-10 my-auto">
              <div className="d-flex align-items-center">
                <p className="subs">
                  <span className="sideTitle">Experiences (Optional)</span>
                  <br />
                  (Add a story, image, video and audio)
                </p>
              </div>
            </div>

            <div className="col-md-10">

              <div className="grayBox" >
                {/* <div className="row" style={{ margin: 0 }}>
                    <div className="col-6">
                      <TextField
                        label="Title of Story"
                        id="title_of_story"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-6">
                      <Dropdown
                        label="Conflict"
                        placeholder="Pick a conflict (Opt)"
                        id="story_type"
                        onChange={handleChange}
                        options={storyTypes}
                      />
                    </div>
                  </div> */}

                {/* <div className="row" style={{ margin: 0 }}>
                    <div className="col-6">
                      <TextField
                        label="Author"
                        id="author"
                        onChange={handleChange}
                      />
                    </div>
                  </div> */}
                <div className="ml-4" style={{ width: "93%" }}>
                  <div className="row" style={{ margin: 0 }}>
                    <div className="col-12">
                      <div className="row pt-4">
                        <div className="col-12">
                          <div id="exp">
                            <div>
                              <div id="exp1">
                                {/* <div className="expHeader1">Experience (Optional)</div>  */}
                                <div>
                                  {/* <h3
                                    className="enterStoryHeader"
                                    style={{ fontSize: 16 }}
                                  >
                                    Story
                                  </h3> */}
                                  <textarea
                                    rows="8"
                                    className="textBox wysihtml5-editor placeholder"
                                    id="txtexp"
                                    style={{ color: "black" }}
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>

          <div
            className="row"
            style={{ margin: 0 }}
          >
            <div className="col-md-10">
              <div
                className="d-flex align-items-center py-auto"
              >
                <p className="subs">
                  <span className="sideTitle">Media</span>
                  {/* <br /> */}
                  {/* (Add images) */}
                </p>
              </div>
            </div>

            <div className="col-md-10">
              <div className="grayBox d-flex justify-content-center align-items-center">
                <div className="smallBox d-flex justify-content-center align-items-center">
                  <span className="fileInput">
                    <input
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      className="inputfile"
                      id="image_url"
                      onChange={handleImg}
                      multiple
                    />
                    <img src={ImgSymbol} alt="tap to add" />
                    <p className="tapText">
                      Tap to Upload
                      <br /> Image
                    </p>
                    <div className={picture.length > 0 ? "sealGreen" : "seal"}>
                      {picture.length} image(s)
                    </div>
                  </span>

                  {/* <span className="fileInput mx-5">
                    <input
                      type="file"
                      accept=".m4a, .mp4, .f4v, .m4b, .m4r, f4b, .mov, .3gp, .wmv, .mpeg, .avi, .webm, .flv, .hdv"
                      className="inputfile"
                      id="video_url"
                      onChange={handleVideo}
                      multiple
                    />
                    <img src={VideoSymbol} alt="tap to add video" />
                    <p className="tapText">
                      Tap to Upload
                      <br /> Video
                    </p>
                  </span> */}

                  {/* <span className="fileInput">
                    <input
                      type="file"
                      accept=".mp3, .wma, .aac, .ogg, .flac, .pcm, .wav, .aiff, .alac"
                      className="inputfile"
                      id="audio_url"
                      onChange={handleAudio}
                      multiple
                    />
                    <img src={AudioSymbol} alt="tap to add audio" />
                    <p className="tapText">
                      Tap to Upload
                      <br /> Audio
                    </p>
                  </span> */}
                </div>
              </div>
            </div>
          </div>
          <div className="row my-5 ml-n5">
            <div className="col-md-4 ml-n5"></div>
            <div className="col-md-4 ml-n5"></div>

            <div className="col-md-4" style={{marginLeft: '6rem !important'}}>
              <Stack horizontal tokens={{ childrenGap: 10 }}>
                <Link to="/stories">
                  <DefaultButton text="Back" allowDisabledFocus disabled={on} checked={on} />
                </Link>

                <DefaultButton text="Submit" onClick={submit} allowDisabledFocus checked={on}
                  style={{ background: '#000', color: '#fff' }}

                />
              </Stack>

            </div>
          </div>


        </form>
      </div>
    </div>
  );
};

export default withAddPersonApi(AddPerson);
{/* <div className="row" style={{ margin: 0, marginBottom: "3.3rem" }}>
            <div className="col-12">
              <div className="d-flex justify-content-center">
                <Link to="/stories">
                  <button className="submitBtn my-4">Back</button>
                </Link>

                <button
                  className="subBtn my-4 ml-3"
                  onClick={submit}
                  disabled={on}
                  style={{ background: on ? "gray" : "black" }}
                >
                  Submit
                </button>
                {on ? (
                  <span style={{ display: "inline-block" }} className="my-auto">
                    <img src={Loader} alt="loading" width="50" height="50" />
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        */}