import React, { useEffect, useState } from "react";
import { AddPersonApi } from "../../utils/apis";
console.log("jquery check", window.$.wysihtml5);
var $ = window.$;
export default Component => ({ props }) => {
  const [data, setData] = useState({});
  const [image, setImage] = useState();
  const [picture, setPicture] = useState([]);
  const [audio, setAudio] = useState([]);
  const [video, setVideo] = useState([]);
  const [on, setOn] = useState(false);
  const uploaddata = new FormData();

  var categoryOptions = [
    { key: "1", text: "Military" },
    { key: "2", text: "Civilian" },
    { key: "3", text: "Paramilitary" }
  ];

  var profileOptions = [
    { key: "1", text: "Military" },
    { key: "2", text: "Civilian" },
    { key: "3", text: "Paramilitary" }
  ];

  var statusOptions = [
    { key: "1", text: "Dead" },
    { key: "2", text: "Alive" }
  ];

  var storyTypes = [
    { key: "1", text: "conflict1" },
    { key: "2", text: "conflict2" },
    { key: "3", text: "conflict3" },
    { key: "4", text: "conflict4" }
  ];
  const setWyisyg = id => {
    $ = window.$;
    $(id).wysihtml5({
      toolbar: {
        fa: true
      }
    });
  };
  useEffect(() => {
    setWyisyg("#txtexp");

    $(document).ready(function() {
      // Basic
      $(".dropify").dropify({
        // tpl: {
        //   message:
        //     '<div className="dropify-message"><span className="file-icon"></span><p className="dragText">Drag and Drop Image or <b style="color: black">Browse</b></p></div>'
        // }
      });

      // Translated
      $(".dropify-fr").dropify({
        messages: {
          default: "Glissez-déposez un fichier ici ou cliquez",
          replace: "Glissez-déposez un fichier ou cliquez pour remplacer",
          remove: "Supprimer",
          error: "Désolé, le fichier trop volumineux"
        }
      });

      // Used events
      var drEvent = $("#input-file-events").dropify();

      // drEvent.on('dropify.beforeClear', function(event, element){
      //     return confirm("Do you really want to delete \"" + element.file.name + "\" ?");
      // });

      drEvent.on("dropify.afterClear", function(event, element) {
        alert("File deleted");
      });

      drEvent.on("dropify.errors", function(event, element) {});

      var drDestroy = $("#input-file-to-destroy").dropify();
      drDestroy = drDestroy.data("dropify");
      $("#toggleDropify").on("click", function(e) {
        e.preventDefault();
        if (drDestroy.isDropified()) {
          drDestroy.destroy();
        } else {
          drDestroy.init();
        }
      });
    });
  }, []);

  const handleChange = (e, b) => {
    if (typeof b === "string") {
      setData({ ...data, [e.target.id]: e.target.value });
    } else {
      setData({ ...data, [e.target.id]: b.key });
    }
  };

  const handleImg = e => {
    const reader = new FileReader();
    const files = e.target.files;
    let imgArr = [];
    for (let i = 0; i < e.target.files.length; i++) {
      imgArr.push(e.target.files[i]);
    }
    reader.onload = () => {
      setPicture(imgArr);
    };
    reader.readAsDataURL(files[0]);
  };

  const handleVideo = e => {
    const reader = new FileReader();
    const files = e.target.files;
    let videoArr = [];
    for (let i = 0; i < e.target.files.length; i++) {
      videoArr.push(e.target.files[i]);
    }
    reader.onload = () => {
      setVideo(videoArr);
    };

    reader.readAsDataURL(files[0]);
  };

  const handleAudio = e => {
    const reader = new FileReader();
    const files = e.target.files;
    let audioArr = [];
    for (let i = 0; i < e.target.files.length; i++) {
      audioArr.push(e.target.files[i]);
    }
    reader.onload = () => {
      setAudio(audioArr);
    };

    reader.readAsDataURL(files[0]);
  };

  const handleFiles = e => {
    const reader = new FileReader();
    const files = e.target.files[0];
    reader.onload = () => {
      setImage(files);
    };

    reader.readAsDataURL(files);
  };

  const submit = async e => {
    e.preventDefault();
    setOn(true);
    var content = $("#txtexp").val();
    setData({ ...data, content: content });
    if (content.length > 0) {
      uploaddata.append("title", data.title);
      uploaddata.append("state", data.state);
      uploaddata.append("spouse", data.spouse);
      uploaddata.append("relatives", data.relatives);
      uploaddata.append("pre_war_occupation", data.pre_war_occupation);
      uploaddata.append("post_war_occupation", data.post_war_occupation);
      uploaddata.append("marital_status", data.marital_status);
      uploaddata.append("children", data.children);
      uploaddata.append("category", data.category);
      uploaddata.append("age", data.age);
      uploaddata.append("archive_name", data.archive_name);
      uploaddata.append("status", data.status);
      uploaddata.append("current_location", data.current_location);
      uploaddata.append("year_of_death", data.year_of_death);
      uploaddata.append("cause_of_death", data.cause_of_death);
      uploaddata.append("content", content);
      uploaddata.append("archive_picture_url", image);
      // uploaddata.append("author", data.author)
      picture.forEach(image_file => {
        uploaddata.append("picture_url[]", image_file);
      });

      video.forEach(video_file => {
        uploaddata.append("video_url[]", video_file);
      });

      audio.forEach(audio_file => {
        uploaddata.append("audio_url[]", audio_file);
      });
      let result = await AddPersonApi(uploaddata);

      props.history.push("/searchperson");
    } else {
      setOn(false);
    }
  };
  return (
    <Component
      submit={submit}
      handleChange={handleChange}
      handleAudio={handleAudio}
      handleFiles={handleFiles}
      handleImg={handleImg}
      handleVideo={handleVideo}
      on={on}
      profileOptions={profileOptions}
      statusOptions={statusOptions}
      storyTypes={storyTypes}
      categoryOptions={categoryOptions}
      picture={picture}
    />
  );
};
