import React from "react";
import Header from "../header/header";
import Footer from "../footer/footer";

const Layout = props => {
  return (
    <div>
      <div>
        <div className="navbar__wrapper">
          <Header />
        </div>
        {window.scrollTo(0, 0)}
        {props.children}
        <div>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Layout;
