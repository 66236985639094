import React, { useState } from "react";
import "./mParticipate.css";
import participateImg from "../../../assests/image/participate.webp";
import participate2 from "../../../assests/image/participate2.webp";
import mask1 from "../../../assests/image/Mask_1.webp";
import bifraTown from "../../../assests/image/biafraTown.webp";
import participate1 from "../../../assests/image/participate1.webp";
import { Link } from "react-router-dom";
import { Stack, CompoundButton } from "office-ui-fabric-react";
import Plus from "../../../assests/image/plus.png";
import Minus from "../../../assests/image/minus.png";
import { Accordion, Card, Button } from 'react-bootstrap';
import PrivacyContent from './privacydata';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';

const MParticipate = (props) => {
  const renderImg = Image => {
    return (
      <div>
        <img src={Image} alt="tell a story" className="tellImg mb-5" />
      </div>
    );
  };
  return (
    <div>
      <div className="row sectionBgWhite">
        <div className="col-12 topSpace">
          <h2 className="mobileTitle mb-4">
            Add your contribution to a survivor’s life
          </h2>

          <p className="pTxt">
            We welcome material provided by family members in particular,
            including digital copies of photos and documents relating to a
            survivor's life and links to other relevant online resources.
            <br />
            <br /> To add to any survivor to the record page, simply scroll to
            the 'Add your contribution' section, click the 'contribute' button,
            and then follow the on-screen instructions. We ask for a little
            information about you, and also whether you would like your details
            published for other researchers with an interest in the same
            survivor to contact you directly. If you don’t wish to contribute
            information online, or you cannot find a record for someone who
            should be recorded, please fill in this{" "}
            <Link to="/addperson">form</Link>
          </p>
        </div>
      </div>

      <section className="row sectionBgBlack pb-4">
        <div className="col-12">
          <h3 className="titleMobWhite mt-5 mb-4">Tell a story</h3>
          {renderImg(bifraTown)}
          <p className="contentTxt">
            We are very keen to add information about survivors still living
            in the museum. It is important when contributing information to
            the record of a living person that you seek permission from this
            person for this information to be included. The Museum has the right
            to modify or remove user-contributed material at its sole discretion
            and without notification.
            <br />
            <br /> If you are contributing information on a living person please
            use this <Link to="/addstory">form</Link>
          </p>
        </div>
      </section>

      <section className="row sectionBgWhite pb-5">
        <div className="col-12">
          <h3 className="titleMobBlack mt-5 mb-4">
            Share Pictures/Media of Artefact
          </h3>
          {renderImg(mask1)}
          <p className="pTxt">
            Letters, emails, diaries and photos are immensely important in
            telling the stories of the survivors of the Biafran war. If you have
            this type of material, you can scan and upload it directly onto your
            service person's record as a JPEG image or a PDF document.
            Otherwise, please allow us to add it to the memorial museum. We will
            scan it and send it back to you along with a copy of the finished
            record for your inspection.
            <br />
            <br />
            If you are sending us this type of content about a person who fought
            in the war please include the service number of the person your
            information refers to. This is because the official records often
            have variations of the same name (e.g. Jack Smith, John Smith, J.A.
            Smith) and we do our best to keep the biographies connected.
            <br />
            <br />
            Where possible, we would prefer the original photo or a good quality
            reprint. A good-quality photocopy would also be acceptable provided
            it is legible enough for us to transcribe. Please include as much
            information as you have about the photo or image, for example, names,
            location, date, cemetery or memorial. If you are using a digital
            camera to photograph a grave or memorial, please use the camera's
            highest resolution and send the image to us in JPEG, or if possible,
            TIFF file format.<br></br>
            Read our digitisation guidelines for help creating digital copies of
            your photos and documents.
          </p>
        </div>
      </section>

      <section className="row sectionBgBlack">
        <div className="col-12">
          <h3 className="titleMobWhite mt-5 mb-4">Can I help?</h3>
          <p className="contentTxt">
            We have a small volunteer team and sadly are unable to cover much
            ground as we hope. We are therefore asking for help from members of
            the public to assist us with cases that are related to this project.
          </p>
          <div className="mt-5 mb-4 pb-4">
            <a href="mailto:kedu@centreformemories.org">
              <CompoundButton
                secondaryText="Choose how to contribute to this project"
                style={{ background: "#FFFFFF", color: "#000000", width: 281 }}
                styles={{ secondaryText: { color: "#000000" } }}
              >
                Contact Us
              </CompoundButton>
            </a>
          </div>
        </div>
      </section>

      <section className="row sectionBgWhite">
        <div className="col-12">
          <h3 className="titleMobBlack mt-5 mb-4">
            Historical Information and Materials
          </h3>
          {renderImg(participate1)}
          <p className="pTxt">
            If you have physical artefacts or materials associated with the
            Nigeria - Biafra Civil War then please visit our FAQ for further
            information on what you can do with these.
          </p>
          <div className="mt-5 mb-4 pb-4">
            <a href="mailto:kedu@centreformemories.org">
              <CompoundButton
                secondaryText="Choose how to contribute to this project"
                style={{ background: "#000000", color: "#FFFFFF", width: 281 }}
                styles={{ secondaryText: { color: "#FFFFFF" } }}
                className="hoverLink"
              >
                Contact Us
              </CompoundButton>
            </a>
          </div>
        </div>
      </section>

      <section className="row sectionBgBlack">
        <div className="col-12">
          <h3 className="titleMobWhite mt-5 mb-4">Donate</h3>
          {renderImg(participateImg)}
          <p className="contentTxt">
            Become part of the Nigeria - Biafra War Memories volunteer community
            at your own pace, in your own time. You can visit your local war
            memorials, take photographs and report on their condition on this
            portal.
          </p>
          <div className="mt-5 mb-4 pb-4">
            <a href="mailto:kedu@centreformemories.org">
              <CompoundButton
                secondaryText="Choose how to contribute to this project"
                style={{ background: "#FFFFFF", color: "#000000", width: 281 }}
                styles={{ secondaryText: { color: "#000000" } }}
              >
                Contact Us
              </CompoundButton>
            </a>
          </div>
        </div>
      </section>

      <section className="row sectionBgWhite pb-4">
        <div className="col-12">
          <h3 className="titleMobBlack mt-5 mb-4">Contribution of Content</h3>
          <p>
            By contributing any material via this website, you are confirming
            that:
            <br />
            <br />
            Your contribution is your original work and you are fully
            entitled to grant to the Nigeria - Biafra War Memories team the
            rights to your materials;
            <br />
            <br />
            You grant the Nigeria - Biafra War Memories team a worldwide,
            royalty-free, non-exclusive, license to reproduce, modify or use
            that material or otherwise exercise any right that any holder of the
            copyright in that material may hold for the duration of the
            applicable copyright;
            <br />
            <br />
            Contributed material must not include material that infringes any
            intellectual property or privacy rights, breaches any suppression
            order or any other law, or be purposefully inflammatory.
            <br />
            <br />
            The Nigeria - Biafra War Memories team has the right to modify or
            remove user-contributed material at its sole discretion and without
            notification.
          </p>
        </div>
      </section>

      <section>


        <Accordion defaultActiveKey="0">
          {
            PrivacyContent.map((x, index) => {
              return (<Card key={"pri" + index}>
                <Accordion.Toggle as={Card.Header} id="arparticipate" eventKey={index}>
                  <div> <h2>{x.header}</h2> <span><i className="fas fa-chevron-down"></i></span>  </div>             
                </Accordion.Toggle>
                <Accordion.Collapse eventKey={index} >
                  <Card.Body id="privacy_content" >{x.content}</Card.Body>
                </Accordion.Collapse>
              </Card>
              );
            })
          }
        </Accordion>
      </section>
      {/* <img src={Plus} alt="open" className="floatSign" /> */}


      <section className="row sectionBgBlack">
        <div className="col-12">
          <h3 className="titleMobWhite mt-5 mb-4">Need more help?</h3>
          {renderImg(participate2)}
          <p className="contentTxt">
            Do you have questions about how best to contribute to the museum?
            Would you like to request the creation of a new record? Contact our
            helpful staff in the Centre for Memories team by phone or use the
            Contact Us button below.
          </p>
          <div className="mt-5 mb-4 pb-4">
            <a href="mailto:kedu@centreformemories.org">
              <CompoundButton
                secondaryText="Choose how to contribute to this project"
                style={{ background: "#FFFFFF", color: "#000000", width: 281 }}
                styles={{ secondaryText: { color: "#000000" } }}
              >
                Contact Us
              </CompoundButton>
            </a>
          </div>
        </div>
      </section>

      <section className="row sectionBgWhite pb-4">
        <div className="col-12">
          <h3 className="titleMobBlack mt-5 mb-4">Privacy Statement</h3>
          <p className="pTxt">
            NBWM collects information and data from publicly available official
            sources and contributions from third parties to create a
            national biographical database recording and preserving the memories
            and honouring the lives of Nigerians who served or were affected by
            the war.
            <br />
            <br />
            We collect data on the service experiences of people still living or
            dead. Participating and contributing data to NBWM is entirely
            voluntary. Individuals featured on having the right to ask for a
            printed or digital copy of any information we hold about them. They
            may also ask to have it removed or corrected if wrong.
            <br />
            <br />
            If you would like a copy of your information or have it removed or
            corrected, please contact us +234 11 22 33 00 or send us an email on
            {"  "}
            <a href="mailto:kedu@centreformemories.org">
              kedu@centreformemories.org
            </a>
          </p>
        </div>
      </section>
    </div >
  );
};

export default MParticipate;
