import React from "react";
import {
  Dropdown,
  ResponsiveMode,
  CompoundButton
} from "office-ui-fabric-react";
import AuthorRating from "../../../components/rating/authorRating";
import story2 from "../../../assests/image/story2.webp";
import { Link } from "react-router-dom";
import withStoriesAPi from "../storiesUtils";
import NoImage from "../../../assests/image/no-image.webp";
import Loader from "../../../assests/image/loading.gif";
const MStories = props => {
  var {
    error,
    load,
    stories,
    handleStory,
    ReportImg,
    pageSize,
    MoveToNextPage,
    MoveToPrevious
  } = props;
  {
    window.scrollTo(0, 0);
  }

  const renderStories = () => {
    if (load) {
      return (
        <div className="text-center">
          <img src={Loader} alt="loading" width="100" height="100" />
        </div>
      );
    } else {
      return stories.liveData.map((s, index) => {
        return (
          <div
            className="row sectionBgWhite storiesItem"
            key={"headlstories:" + index}
          >
            <div className="col-7">
              <h3
                className="storyTitle"
                onClick={() => handleStory(s.story.id)}
              >
                {s.story.title}
              </h3>
              <div
                className="story_sub_content"
                id="con"
                dangerouslySetInnerHTML={{
                  __html:
                    s.story.content.length < 200
                      ? s.story.content
                      : s.story.content.slice(0, 200) + "..."
                }}
              ></div>
              <div className="storyCat">Testimonies, Survivors</div>
              <div className="author">
                {s.story.author} {"   "}
                <AuthorRating />
              </div>
            </div>
            <div className="col-5">
              <div>
                <img
                  src={
                    s.picture_url.length === 0
                      ? NoImage
                      : s.picture_url[0].picture_url
                  }
                  alt=""
                  width="213"
                  height="186"
                  className="story_sub_img_fit"
                  style={{ border: "1px solid lightgray" }}
                />
                <p className="reportPic">Report this photo</p>
              </div>
            </div>
          </div>
        );
      });
    }
  };

  const renderPopularStories = () => {
    return stories.liveData.map((s, index) => {
      return (
        <div
          className="row sectionBgWhite storiesItem"
          key={"popstory:" + index}
        >
          <div className="col-7">
            <h5 className="popHeader">{s.story.title}</h5>
            <div
              className="popTxt"
              id="con"
              dangerouslySetInnerHTML={{
                __html:
                  s.story.content.length < 140
                    ? s.story.content
                    : s.story.content.slice(0, 140) + "..."
              }}
            ></div>

            <div className="popCat">Category, Category</div>
            <div className="popAuthor">
              {s.story.author} {"   "}
              <AuthorRating />
            </div>
          </div>
        </div>
      );
    });
  };

  return (
    <div>
      <div className="row sectionBgWhite">
        <div className="col-12 topSpace">
          <h2 className="mobileTitle mb-4">Stories</h2>
        </div>
      </div>
      <section>{renderStories()}</section>

      <div className="row mb-3" style={{ margin: 0, color: "#605E5C" }}>
        <div className=" col-md-12 col-xs-12 col-sm-12 d-flex justify-content-center my-4">
          {stories.totalresult > 4 ? (
            <div className="d-flex justify-content-space-between align-items-baseline">
              {stories.page > 1 && (
                <div onClick={() => MoveToPrevious(stories.page - 1)}>
                  <span className="fas fa-angle-double-left clickIt"></span>
                  <span className="fas fa-angle-left ml-3 clickIt"></span>
                </div>
              )}
              <span className="mx-3">
                <Dropdown
                  placeholder="pages"
                  defaultSelectedKey={stories.page}
                  responsiveMode={ResponsiveMode.large}
                  options={Array(Math.ceil(stories.totalresult / stories.size))
                    .fill(1)
                    .map((x, index) => {
                      return { key: index + 1, text: index + 1 };
                    })}
                  styles={{ dropdown: { width: 100 } }}
                  onChange={(e, index) => MoveToNextPage(index.key)}
                  onSelect={(e, key) => {}}
                />
              </span>
              <span style={{ fontSize: 14 }}>
                {" "}
                of {stories &&
                  Math.ceil(stories.totalresult / stories.size)}{" "}
                &nbsp;pages
              </span>
              &nbsp;&nbsp;
              {stories.totalresult > stories.page * stories.size && (
                <div onClick={() => MoveToNextPage(stories.page + 1)}>
                  <i className="fas fa-angle-right mr-3 clickIt"></i>
                  <i className="fas fa-angle-double-right clickIt"></i>
                </div>
              )}
            </div>
          ) : null}
        </div>
      </div>

      <section>
        <div className="row sectionBgWhite pb-2">
          <div className="col-12">
            <h4 className="popTitle">Popular Stories</h4>
          </div>
        </div>
        {renderPopularStories()}
      </section>

      <section className="row sectionBgWhite my-4">
        <div className="col-12">
          <h3 className="mobileTitle">
            Have an Experience
            <br /> or Story about the
            <br /> War to Share?
          </h3>
          <p className="extraTxt">
            Do you have an experience or story you would like to share with the
            community. You can contribute using the button below.
          </p>

          <div>
            <Link to="/addstory">
              <CompoundButton
                secondaryText="Choose how to contribute to the project"
                className="hoverLink mt-4"
                style={{
                  background: "#000000",
                  color: "#FFFFFF",
                  width: 281,
                  marginBottom: "3rem"
                }}
              >
                Contribute
              </CompoundButton>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};
export default withStoriesAPi(MStories);
