import React, { useState } from "react";
import {
  SearchBox,
  Stack,
  Dropdown,
  Checkbox,
  TextField,
  CompoundButton,
  Label
} from "office-ui-fabric-react";

import MobileResultCard from "../../../components/mobileResultCard/mobileResultCard";
import { GetPersonApi, SearchPersonApi } from "../../../utils/apis";
import Loader from "../../../assests/image/loading.gif";
import "./mPersonSearch.css";
import { Link } from "react-router-dom";
import withPersonUtil from "../personSearchUtils";

const MPersonSearch = props => {
  return (
    <div className="mx-3 pt-5 mt-5 mb-4">
      <div className="row mb-5" style={{ margin: 0 }}>
        <div className="col-md-8 noPadding">
          <p className="search">Person Search</p>
        </div>
      </div>
      <div className="row" style={{ margin: 0 }}>
        <div className="col-md-8 noPadding">
          <Stack horizontal={false} tokens={{ childrenGap: 20 }}>
            <Stack>
              <Label
                style={{
                  color: "#000",
                  fontWeight: 600,
                  fontSize: "15px",
                  lineHeight: "20px"
                }}
              >
                Search
              </Label>
              <SearchBox
                placeholder="Search for person"
                onChange={props.handleSearch}
              />
            </Stack>
            <Checkbox label="Images only" />
            {/* <Dropdown
                            placeholder="Select an option"
                            label="Dropdown with error message"
                            options={[
                                { key: 'A', text: 'Option a' },
                                { key: 'B', text: 'Option b' },
                                { key: 'C', text: 'Option c' },
                                { key: 'D', text: 'Option d' },
                                { key: 'E', text: 'Option e' }
                            ]}

                            styles={{ dropdown: { width: '100%' } }}
                        /> */}
          </Stack>
        </div>

        {/* Result Search Starts */}

        {/**If not searching at all */
        !props.searching.ongoing ? (
          /* Add Person Starts */
          <div>
            <div className="row mt-5" style={{ margin: 0 }}>
              <div className="col-md-12 noPadding lookingForContent">
                Looking for a loved one involved in the war? Search our records
                to find them or add them to our records by using the Add person
                button above.
              </div>
            </div>
            <div className="row my-5" style={{ margin: 0 }}>
              <div className="col-md-7 noPadding">
                <Link to="/addperson">
                  <CompoundButton
                    secondaryText="Add a person to the database"
                    style={{
                      background: "#000000",
                      color: "#FFFFFF",
                      width: 281
                    }}
                    className="hoverLink"
                  >
                    Add person
                  </CompoundButton>
                </Link>
              </div>
            </div>
          </div>
        ) : /* Add Person Ends */
        /**If searching is ongoing and no result yet */
        props.searching.ongoing && props.searching.fetching ? (
          <div className=" col-12 d-flex justify-content-center">
            <img src={Loader} alt="loading" width="100" height="100" />
          </div>
        ) : /**If searching is ongoing and result is gotten */
        props.searching.ongoing &&
          !props.searching.fetching &&
          props.persons.count > 0 ? (
          props.persons.result.map((p, index) => {
            return (
              <div className="searchResults" key={"div_search" + index}>
                <div className="row mt-5" style={{ margin: 0 }}>
                  <div className="col-md-8 noPadding">
                    Showing {++index} results out of {props.persons.count}{" "}
                  </div>
                </div>
                <div className="row mt-5" style={{ margin: 0 }}>
                  <div className="col-md-12 noPadding">
                    <div className="cardSettings">
                      <MobileResultCard
                        key={index}
                        name={p.archive.archive_name}
                        pic={p.archive.archive_picture_url}
                        id={p.archive.id}
                      />
                    </div>
                  </div>
                </div>
              </div>
            );
          })
        ) : (
          /* No Result Search Starts */
          <div className="col-md-12 col-xs-12 col-sm-12 noPadding">
            <div className="row mt-5" style={{ margin: 0 }}>
              <div className="col-md-2 col-sm-2 col-xs-2 cant">
                <div className="cantTxt">Can’t find who you’re looking for</div>
              </div>
              <div className="col-md-12 mt-3 cantContent">
                If you can't find the person you're looking for, it may be that
                a record has not been created for this individual yet. Learn how
                you can contribute to the museum record to enrich this resource
                for everyone.
              </div>
            </div>
            <div className="col-md-12 my-5" style={{ padding: 0 }}>
              <Link to="/addperson">
                <CompoundButton
                  secondaryText="Choose how to contribute to the museum"
                  style={{
                    background: "#000000",
                    color: "#FFFFFF",
                    width: 281
                  }}
                  className="hoverLink"
                >
                  Contribute
                </CompoundButton>
              </Link>
            </div>
          </div>
        )
        /* No Result Search Ends */
        }

        {/* Result Search Ends */}
      </div>
    </div>
  );
};

export default withPersonUtil(MPersonSearch);
