import React from 'react'

export default [
    {
        header: "Privacy",
        content: <p className="privacy_text">You may browse this website without providing any personal information.  Where you voluntarily provide
        personal information the NBWM team will only use that information for the specific purpose stated.  In
        providing this personal information you agree to its collection and our use of that information for the
        purpose stated at the time of its collection.  The NBWM team will keep any such information secure and will
        only disclose it to third parties as specified in these terms of use.</p>
    },
    {
        header: "Cookies",
        content: <p className="privacy_text">
        The website has been designed to automatically issue a cookie to visitors.  This is used to provide information
        to enable us to analyze overall visitor activities on the website and to provide a site more tailored to your needs
        and interests.  We do not use cookies to collect personal information, nor do we link non-personal information stored
        in cookies with personal information about you.
        </p>
    },
    {
        header: "Giving Your Conscent",
        content: <p className="privacy_text"> When submitting a form, or making a payment, I agree to my details being used by the NBWM team for this project.
        I understand my data will be held securely and will not be distributed to other parties except as required by law.
        I have a right to change or access my information at any time.
        </p>
    },
    {
        header: "Disclaimer",
        content: <p className="privacy_text">We disclaim and exclude all liability for any claim, loss, demands or damages of any kind whatsoever
        (including for negligence) arising out of or in connection with the use of either this website or the information,
        content or materials included on this site or on any website to which it is linked.
                        </p>
    },
    {
        header: "Contact Us",
        content: <p style={{ fontSize: "15px", lineHeight: "24px", letterSpacing: "0.01em" }}>
            Please contact <a href="mailto:kedu@centreformemories.org">kedu@centreformemories.org</a> if you have any questions about these terms of use.
                        </p>
    }
]