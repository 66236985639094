import  React from 'react';
import {DESKTOP, MOBILE, TABLET} from '../../resolution';
import MobileSearch from './mobile/mPersonSearch';
import DesktopSearch from './desktop/personSearch';
import TabletSearch from './tablet/personSearch';

export default (props) =>{
    if(props.resolution === MOBILE){
        return<MobileSearch />
    }
    else if(props.resolution === TABLET){
        return <TabletSearch />
    }else{
        return <DesktopSearch props={props} />
    }
} 