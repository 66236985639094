import React from "react";
import { Link } from "react-router-dom";
export default [
  {
    question: "What is the Nigeria - Biafra War Memories?",
    answer: (
      <p>
        Find out more about the project on the{" "}
        <Link to="/about">About the project</Link>
      </p>
    )
  },
  {
    // id: "",
    question: "Who is behind the  Nigeria - Biafra War Memories Project?",
    answer: (
      <div>
        <p>
          The Nigeria - Biafra War Memories is an initiative championed by The
          Centre for Memories, Enugu. Please visit &nbsp;
          <a href="http://www.centreformemories.org/" target="blank">
            www.centreformemories.com.ng
          </a>
        </p>
        <p>
          Information on war memorials submitted to NBWDM is accessible to the
          public and will be made available to other Archives, Historic
          Environment Records and other heritage bodies to support the
          protection and conservation of our history and heritage.
        </p>
        <p>
          By sharing information here, you will be contributing towards a
          comprehensive understanding of the condition of war at that time as we
          are keen to recognize our loved ones who were involved, lost and or
          are still alive across the country. Our goal is to use this
          information to find ways to protect and conserve war memorials for
          future generations.
        </p>
      </div>
    )
  },
  // {
  //     question: 'Who funds this project?',
  //     answer: <p>
  //         We disclaim and exclude all liability for any claim, loss,
  //          or damages of any kind whatsoever (including for negligence) arising out of or in connection with the use of either this website or the information, content or materials included on this site or on any website to which it is linked.
  //         </p>
  // },
  {
    question:
      " I have searched by name but it does not appear in the search results. How can I find it?",
    answer: (
      <p>
        There are two different options to search: keyword and location. The
        name by which the person is identified on this portal may differ as
        originally known. You may need to search creatively, for example, search
        using a surname, suburb or town name. If you still have difficulties
        after trying both the keyword and location search then it may not be on
        the website in which case please do. &nbsp;{" "}
        <a href="/addperson" target="blank">
          Add Person
        </a>
      </p>
    )
  },
  {
    question: " How do I add a Person to Person records?",
    answer: (
      <p>
        To add a person you need to be on the 'Person Search’ page, scroll to
        the bottom and click on the &nbsp;
        <a href="/addperson" target="blank">
          Add Person
        </a>
      </p>
    )
  },
  {
    question:
      "I cannot upload a photograph to the online portal. What can I do?",
    answer: (
      <p>
        Due to our system, there are some restrictions on the size of images
        that can be uploaded. When you click ‘submit a new image’ on the
        memorial page you will be taken to the ‘submit a new image’ page. On
        this page, all the details about photograph size can be found. At
        present this is a maximum size of 5MB. You may need to reduce the size
        of your image before you can upload it. Acceptable formats include png,
        gif, jpg, and jpeg.
      </p>
    )
  },
  {
    question:
      "I have uploaded a photo to the online portal and I want to remove it (for example, it is the wrong record or I do not like the way it has appeared or been cropped). What can I do?",
    answer: (
      <p>
        Underneath each photograph on the website is a link to 'Report this
        image'. Click on ‘Report this image’ and you will be asked to provide a
        content issue from a dropdown menu and some comments. Once you submit
        this report the image will be removed and reviewed by NBWDM Team.
      </p>
    )
  },
  {
    question: `I have a better photograph of a war memorial than the one
        showing. Should I delete the one there?`,
    answer: (
      <p>
        No, you should only report an image that you believe is inappropriate
        (e.g. it is not of the war memorial). Please do not report images
        because you have uploaded another one - we want to see different images
        over time and from different perspectives. Include your additional image
        and make sure you have an accurate date (where possible) for date photo
        taken thus adding to the timeline of images showing below the main
        image.
      </p>
    )
  },
  {
    question: `I have found an offensive posting or image. What can I do?`,
    answer: (
      <p>
        Please use the 'Report this photo' button on the relevant image to let
        us know and the offensive image will be removed immediately. We will
        review the report in due course.
      </p>
    )
  },
  {
    question: "Can I use photos and materials uploaded to the site?",
    answer: (
      <p>
        Any materials uploaded to this online portal is considered
        non-confidential and non-proprietary and can be used for most purposes.
        Appropriate acknowledgement to contributors should be made, and
        materials cannot be used for commercial purposes.
      </p>
    )
  }
];
